import React from "react"
import classNames from "classnames"
import { StaticImage } from "gatsby-plugin-image"

import { pagesLinks } from "./links"
// import CoffeeIcon from "../../../components/_V2/BusinessTypes/assets/svg/CoffeeIcon"
// import RestaurantIcon from "../../../components/_V2/BusinessTypes/assets/svg/RestaurantIcon"
import componentStyles from "../../../components/_V2/BusinessTypes/styles.module.scss"
import {
	BUBBLES_TYPES,
	BUSINESS_TYPES,
	Data,
} from "../../../components/_V2/BusinessTypes/types"

import styles from "./styles.module.scss"
import {Locales} from "../../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-BY') {
		return {
			title: "Решения на основе данных",
			subTitle: "системный подход",
			readMore: "Смотреть интервью",
			theme: "white",
			changeTitles: true, // Меняет тайтлы карточки, когда переключаешь слайды
			businessType: [
				{
					type: BUSINESS_TYPES.COFFEE_SHOP, // Нужно заменить данные
					tabName: pagesLinks.businessTypes.coffeehouse.text, // Нужно заменить данные
					// icon: CoffeeIcon, // Нужно заменить данные
					name: "Анастасия Филлипова, основатель стрит-фуд проекта «Шоу'рма»",
					cardTitle: (
						<>
							<span className={styles.cardTitle__desktop}>
								Всё необходимое <br/>для франшизы и сетей
							</span>
							<span className={styles.cardTitle__tablet}>
								Всё необходимое <br/>для франшизы и сетей
							</span>
							<span className={styles.cardTitle__mobile}>Ставим сети</span>
					</>
					), // Для changeTitles
					cardSubtitle: "опыт наших клиентов", // Для changeTitles
					details: (
						<>
							Quick Resto сегодня самая сильная и удобная платформа для рестораторов
							созданная на российском рынке. Для нашей сети было очень важно перейти
							на систему с опционалом не хуже передовых
							иностранных аналогов и Quick Resto оправдали наши ожидания.
						</>
					),
					detailsTablet:
						"Quick Resto сегодня самая сильная и удобная платформа для рестораторов созданная на российском рынке. Для нашей сети было очень важно перейти на систему с опционалом не хуже передовых иностранных аналогов и Quick Resto оправдали наши ожидания.",
					detailsMobile:
						"Quick Resto сегодня самая сильная и удобная платформа для рестораторов созданная на российском рынке. Для нашей сети было очень важно перейти на систему с опционалом не хуже передовых иностранных аналогов и Quick Resto оправдали наши ожидания.",
					// photoCaption: "«Здесь должна быть цитата»", // Нужно заменить данные
					// logo: (
					// 	<StaticImage
					// 		className={componentStyles.businessLogo}
					// 		src="../../../components/_V2/BusinessTypes/assets/images/logo/dobro-coffe.png"
					// 		alt="Логотип компании DO.BRO Coffee"
					// 		objectFit="contain"
					// 		placeholder="blurred"
					// 		quality={90}
					// 	/>
					// ),
					photo: (
						<StaticImage
							className={classNames(componentStyles.businessMainImageNew)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/show-room-2.png"
							alt="Анастасия Филлипова"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					type: BUSINESS_TYPES.RESTAURANT, // Нужно заменить данные
					tabName: pagesLinks.businessTypes.restaurant.text, // Нужно заменить данные
					// icon: RestaurantIcon, // Нужно заменить данные
					bubbleType: BUBBLES_TYPES.SECONDARY,
					cardTitle: (
						<>
					<span className={styles.cardTitle__desktop}>
						Всегда прибыльно,
						<br />
						даже в межсезонье
					</span>
							<span className={styles.cardTitle__tablet}>
						Всегда прибыльно,
						<br />
						даже в межсезонье
					</span>
							<span className={styles.cardTitle__mobile}>Всегда прибыльно</span>
						</>
					), // Для changeTitles
					cardSubtitle: "опыт наших клиентов", // Для changeTitles
					name: "Кристиан Ленешмидт, владелец ресторана «Брецель»",
					details: (
						<>
							Лучше всего мы зарабатыаем во время туристического сезона.
							<br />А как получать прибыль и привлекать гостей в межсезонье нам
							рассказали эксперты Quick Resto. За время обучения мы автоматизировали
							работу склада и кухни, благодаря этому увеличили скорость
							обслуживания.
						</>
					),
					detailsTablet:
						"Лучше всего мы зарабатыаем во время туристического сезона. А как получать прибыль и привлекать гостей в межсезонье нам рассказали эксперты Quick Resto. За время обучения мы автоматизировали работу склада и кухни, благодаря этому увеличили скорость обслуживания.",
					detailsMobile:
						"Лучше всего мы зарабатыаем во время туристического сезона. А как получать прибыль и привлекать гостей в межсезонье нам рассказали эксперты Quick Resto. За время обучения мы автоматизировали работу склада и кухни, благодаря этому увеличили скорость обслуживания.",
					// photoCaption: "«Здесь должна быть цитата»", // Нужно заменить данные
					// logo: (
					// 	<StaticImage
					// 		className={componentStyles.businessLogo}
					// 		src="../../../components/_V2/BusinessTypes/assets/images/logo/show-room.png"
					// 		alt="Логотип проекта «Шоу'рма»"
					// 		objectFit="contain"
					// 		placeholder="blurred"
					// 		quality={90}
					// 	/>
					// ),
					photo: (
						<StaticImage
							className={classNames(componentStyles.businessMainImageNew)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/brezel.png"
							alt="Кристиан Ленешмидт"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					type: BUSINESS_TYPES.RESTAURANT, // Нужно заменить данные
					tabName: pagesLinks.businessTypes.restaurant.text, // Нужно заменить данные
					// icon: RestaurantIcon, // Нужно заменить данные
					bubbleType: BUBBLES_TYPES.SECONDARY,
					cardTitle: (
						<>
					<span className={styles.cardTitle__desktop}>
						Полезное обучение
						<br />с продолжением
					</span>
							<span className={styles.cardTitle__tablet}>
						Полезное обучение
						<br />с продолжением
					</span>
							<span className={styles.cardTitle__mobile}>
						Полезное обучение с продолжением
					</span>
						</>
					), // Для changeTitles
					cardSubtitle: "опыт наших клиентов", // Для changeTitles
					name: "Хисманова Елена, главный бухгалтер кафе «Привет Десерт»",
					details: (
						<>
							Удобно. Обучение в режиме онлайн с возможностью задавать вопросы.
							Уже в процессе обучения Алексей устранил наши недочёты. Мы продолжаем
							общаться после обучения и решаем все возникающие вопросы. Благодарю за
							поддержку в автоматизации процессов.
						</>
					),
					detailsTablet:
						"Удобно. Обучение в режиме онлайн с возможностью задавать вопросы. Уже в процессе обучения Алексей устранил наши недочёты. Мы продолжаем общаться после обучения и решаем все возникающие вопросы. Благодарю за поддержку в автоматизации процессов.",
					detailsMobile:
						"Удобно. Обучение в режиме онлайн с возможностью задавать вопросы. Уже в процессе обучения Алексей устранил наши недочёты. Мы продолжаем общаться после обучения и решаем все возникающие вопросы. Благодарю за поддержку в автоматизации процессов.",
					// photoCaption: "«Здесь должна быть цитата»", // Нужно заменить данные
					// logo: (
					// 	<StaticImage
					// 		className={componentStyles.businessLogo}
					// 		src="../../../components/_V2/BusinessTypes/assets/images/logo/show-room.png"
					// 		alt="Логотип проекта «Шоу'рма»"
					// 		objectFit="contain"
					// 		placeholder="blurred"
					// 		quality={90}
					// 	/>
					// ),
					photo: (
						<StaticImage
							className={classNames(componentStyles.businessMainImageNew)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/privet-desert.png"
							alt="Елена Хисманова"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
			],
		}
	}

	if (locale === 'kz-KZ') {
		return {
			title: "Решения на основе данных",
			subTitle: "системный подход",
			readMore: "Смотреть интервью",
			theme: "white",
			changeTitles: true, // Меняет тайтлы карточки, когда переключаешь слайды
			businessType: [
				{
					type: BUSINESS_TYPES.COFFEE_SHOP, // Нужно заменить данные
					tabName: pagesLinks.businessTypes.coffeehouse.text, // Нужно заменить данные
					// icon: CoffeeIcon, // Нужно заменить данные
					name: "Анастасия Филлипова, «Шоу'рма» стрит-фуд жобасын негізін қалаушы",
					cardTitle: (
						<>
					<span className={styles.cardTitle__desktop}>
						Франшизаны дамытамыз
						<br />және желілерді қоямыз
					</span>
							<span className={styles.cardTitle__tablet}>
						Франшизаны дамытамыз
						<br />және желілерді қоямыз
					</span>
							<span className={styles.cardTitle__mobile}>Ставим сети</span>
						</>
					), // Для changeTitles
					cardSubtitle: "клиенттеріміздің тәжірибесі", // Для changeTitles
					details: (
						<>
							Quick Resto бүгінде ресейлік нарықта құрылған рестораторларға арналған ең күшті және ыңғайлы платформа. Біздің желі үшін ресейлік тамыры бар, бірақ опционалы озық шетелдік аналогтардан кем емес жүйеге ауысу өте маңызды болады, және Quick Resto біздің үміттерімізді ақтады.
						</>
					),
					detailsTablet:
						"Quick Resto бүгінде ресейлік нарықта құрылған рестораторларға арналған ең күшті және ыңғайлы платформа. Біздің желі үшін ресейлік тамыры бар, бірақ опционалы озық шетелдік аналогтардан кем емес жүйеге ауысу өте маңызды болады, және Quick Resto біздің үміттерімізді ақтады.",
					detailsMobile:
						"Quick Resto бүгінде ресейлік нарықта құрылған рестораторларға арналған ең күшті және ыңғайлы платформа. Біздің желі үшін ресейлік тамыры бар, бірақ опционалы озық шетелдік аналогтардан кем емес жүйеге ауысу өте маңызды болады, және Quick Resto біздің үміттерімізді ақтады.",
					// photoCaption: "«Здесь должна быть цитата»", // Нужно заменить данные
					// logo: (
					// 	<StaticImage
					// 		className={componentStyles.businessLogo}
					// 		src="../../../components/_V2/BusinessTypes/assets/images/logo/dobro-coffe.png"
					// 		alt="Логотип компании DO.BRO Coffee"
					// 		objectFit="contain"
					// 		placeholder="blurred"
					// 		quality={90}
					// 	/>
					// ),
					photo: (
						<StaticImage
							className={classNames(componentStyles.businessMainImageNew)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/show-room-2.png"
							alt="Анастасия Филлипова"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					type: BUSINESS_TYPES.RESTAURANT, // Нужно заменить данные
					tabName: pagesLinks.businessTypes.restaurant.text, // Нужно заменить данные
					// icon: RestaurantIcon, // Нужно заменить данные
					bubbleType: BUBBLES_TYPES.SECONDARY,
					cardTitle: (
						<>
					<span className={styles.cardTitle__desktop}>
						Әрқашан пайдалы, <br />
						тіпті маусымаралықта да
					</span>
							<span className={styles.cardTitle__tablet}>
						Әрқашан пайдалы, <br />
						тіпті маусымаралықта да
					</span>
							<span className={styles.cardTitle__mobile}>Әрқашан пайдалы</span>
						</>
					), // Для changeTitles
					cardSubtitle: "клиенттеріміздің тәжірибесі", // Для changeTitles
					name: "Кристиан Ленешмидт, «Брецель» мейрамханасының иесі",
					details: (
						<>
							Біз туристтік маусым кезінде ең көп ақша табамыз. Ал маусымаралықта пайданы қалай табуға болатынын және қонақтарты қалай тартатынын бізге Quick Resto сарапшылары айтып берді. Оқу кезінде біз қойма мен асүй жұмысын автоматтандырдық, осының арқасында қызмет көрсету жылдамдығын ұлғайттық.
						</>
					),
					detailsTablet:
						"Біз туристтік маусым кезінде ең көп ақша табамыз. Ал маусымаралықта пайданы қалай табуға болатынын және қонақтарты қалай тартатынын бізге Quick Resto сарапшылары айтып берді. Оқу кезінде біз қойма мен асүй жұмысын автоматтандырдық, осының арқасында қызмет көрсету жылдамдығын ұлғайттық.",
					detailsMobile:
						"Біз туристтік маусым кезінде ең көп ақша табамыз. Ал маусымаралықта пайданы қалай табуға болатынын және қонақтарты қалай тартатынын бізге Quick Resto сарапшылары айтып берді. Оқу кезінде біз қойма мен асүй жұмысын автоматтандырдық, осының арқасында қызмет көрсету жылдамдығын ұлғайттық.",
					// photoCaption: "«Здесь должна быть цитата»", // Нужно заменить данные
					// logo: (
					// 	<StaticImage
					// 		className={componentStyles.businessLogo}
					// 		src="../../../components/_V2/BusinessTypes/assets/images/logo/show-room.png"
					// 		alt="Логотип проекта «Шоу'рма»"
					// 		objectFit="contain"
					// 		placeholder="blurred"
					// 		quality={90}
					// 	/>
					// ),
					photo: (
						<StaticImage
							className={classNames(componentStyles.businessMainImageNew)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/brezel.png"
							alt="Кристиан Ленешмидт"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					type: BUSINESS_TYPES.RESTAURANT, // Нужно заменить данные
					tabName: pagesLinks.businessTypes.restaurant.text, // Нужно заменить данные
					// icon: RestaurantIcon, // Нужно заменить данные
					bubbleType: BUBBLES_TYPES.SECONDARY,
					cardTitle: (
						<>
					<span className={styles.cardTitle__desktop}>
						Жалғасуы бар
						<br />пайдалы оқу
					</span>
							<span className={styles.cardTitle__tablet}>
						Жалғасуы бар
						<br />пайдалы оқу
					</span>
							<span className={styles.cardTitle__mobile}>
						Жалғасуы бар пайдалы оқу
					</span>
						</>
					), // Для changeTitles
					cardSubtitle: "клиенттеріміздің тәжірибесі", // Для changeTitles
					name: "Хисманова Елена, «Привет Десерт» кафесінің бас бухгалтері\n",
					details: (
						<>
							Ыңғайлы. Сұрақтар қою мүмкіндігімен онлайн режимде оқу Оқу процесінде Алексей біздің кемшіліктерімізді жойып қойды.  Біз оқудан кейін тілдесуді жалғастырамыз және барлық туындайтын мәселелерді шешеміз. Процестерді автоматтандыруда қолдау көрсеткеніңіз үшін алғыз айтамыз.
						</>
					),
					detailsTablet:
						"Ыңғайлы. Сұрақтар қою мүмкіндігімен онлайн режимде оқу Оқу процесінде Алексей біздің кемшіліктерімізді жойып қойды.  Біз оқудан кейін тілдесуді жалғастырамыз және барлық туындайтын мәселелерді шешеміз. Процестерді автоматтандыруда қолдау көрсеткеніңіз үшін алғыз айтамыз.",
					detailsMobile:
						"Ыңғайлы. Сұрақтар қою мүмкіндігімен онлайн режимде оқу Оқу процесінде Алексей біздің кемшіліктерімізді жойып қойды.  Біз оқудан кейін тілдесуді жалғастырамыз және барлық туындайтын мәселелерді шешеміз. Процестерді автоматтандыруда қолдау көрсеткеніңіз үшін алғыз айтамыз.",
					// photoCaption: "«Здесь должна быть цитата»", // Нужно заменить данные
					// logo: (
					// 	<StaticImage
					// 		className={componentStyles.businessLogo}
					// 		src="../../../components/_V2/BusinessTypes/assets/images/logo/show-room.png"
					// 		alt="Логотип проекта «Шоу'рма»"
					// 		objectFit="contain"
					// 		placeholder="blurred"
					// 		quality={90}
					// 	/>
					// ),
					photo: (
						<StaticImage
							className={classNames(componentStyles.businessMainImageNew)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/privet-desert.png"
							alt="Елена Хисманова"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
			],
		}
	}

	if (locale === 'ru-KZ') {
		return {
			title: "Решения на основе данных",
			subTitle: "системный подход",
			readMore: "Смотреть интервью",
			theme: "white",
			changeTitles: true, // Меняет тайтлы карточки, когда переключаешь слайды
			businessType: [
				{
					type: BUSINESS_TYPES.COFFEE_SHOP, // Нужно заменить данные
					tabName: pagesLinks.businessTypes.coffeehouse.text, // Нужно заменить данные
					// icon: CoffeeIcon, // Нужно заменить данные
					name: "Анастасия Филлипова, основатель стрит-фуд проекта «Шоу'рма»",
					cardTitle: (
						<>
					<span className={styles.cardTitle__desktop}>
						Развиваем франшизу
						<br />и ставим сети
					</span>
							<span className={styles.cardTitle__tablet}>
						Развиваем франшизу
						<br />и ставим сети
					</span>
							<span className={styles.cardTitle__mobile}>Ставим сети</span>
						</>
					), // Для changeTitles
					cardSubtitle: "опыт наших клиентов", // Для changeTitles
					details: (
						<>
							Quick Resto сегодня самая сильная и удобная платформа для рестораторов
							на рынке. Для нашей сети было очень важно перейти
							на систему с опционалом не хуже передовых
							иностранных аналогов и Quick Resto оправдали наши ожидания.
						</>
					),
					detailsTablet:
						"Quick Resto сегодня самая сильная и удобная платформа для рестораторов созданная на рынке. Для нашей сети было очень важно перейти на систему с опционалом не хуже передовых иностранных аналогов и Quick Resto оправдали наши ожидания.",
					detailsMobile:
						"Quick Resto сегодня самая сильная и удобная платформа для рестораторов созданная на рынке. Для нашей сети было очень важно перейти на систему с опционалом не хуже передовых иностранных аналогов и Quick Resto оправдали наши ожидания.",
					// photoCaption: "«Здесь должна быть цитата»", // Нужно заменить данные
					// logo: (
					// 	<StaticImage
					// 		className={componentStyles.businessLogo}
					// 		src="../../../components/_V2/BusinessTypes/assets/images/logo/dobro-coffe.png"
					// 		alt="Логотип компании DO.BRO Coffee"
					// 		objectFit="contain"
					// 		placeholder="blurred"
					// 		quality={90}
					// 	/>
					// ),
					photo: (
						<StaticImage
							className={classNames(componentStyles.businessMainImageNew)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/show-room-2.png"
							alt="Анастасия Филлипова"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					type: BUSINESS_TYPES.RESTAURANT, // Нужно заменить данные
					tabName: pagesLinks.businessTypes.restaurant.text, // Нужно заменить данные
					// icon: RestaurantIcon, // Нужно заменить данные
					bubbleType: BUBBLES_TYPES.SECONDARY,
					cardTitle: (
						<>
					<span className={styles.cardTitle__desktop}>
						Всегда прибыльно,
						<br />
						даже в межсезонье
					</span>
							<span className={styles.cardTitle__tablet}>
						Всегда прибыльно,
						<br />
						даже в межсезонье
					</span>
							<span className={styles.cardTitle__mobile}>Всегда прибыльно</span>
						</>
					), // Для changeTitles
					cardSubtitle: "опыт наших клиентов", // Для changeTitles
					name: "Кристиан Ленешмидт, владелец ресторана «Брецель»",
					details: (
						<>
							Лучше всего мы зарабатыаем во время туристического сезона.
							<br />А как получать прибыль и привлекать гостей в межсезонье нам
							рассказали эксперты Quick Resto. За время обучения мы автоматизировали
							работу склада и кухни, благодаря этому увеличили скорость
							обслуживания.
						</>
					),
					detailsTablet:
						"Лучше всего мы зарабатыаем во время туристического сезона. А как получать прибыль и привлекать гостей в межсезонье нам рассказали эксперты Quick Resto. За время обучения мы автоматизировали работу склада и кухни, благодаря этому увеличили скорость обслуживания.",
					detailsMobile:
						"Лучше всего мы зарабатыаем во время туристического сезона. А как получать прибыль и привлекать гостей в межсезонье нам рассказали эксперты Quick Resto. За время обучения мы автоматизировали работу склада и кухни, благодаря этому увеличили скорость обслуживания.",
					// photoCaption: "«Здесь должна быть цитата»", // Нужно заменить данные
					// logo: (
					// 	<StaticImage
					// 		className={componentStyles.businessLogo}
					// 		src="../../../components/_V2/BusinessTypes/assets/images/logo/show-room.png"
					// 		alt="Логотип проекта «Шоу'рма»"
					// 		objectFit="contain"
					// 		placeholder="blurred"
					// 		quality={90}
					// 	/>
					// ),
					photo: (
						<StaticImage
							className={classNames(componentStyles.businessMainImageNew)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/brezel.png"
							alt="Кристиан Ленешмидт"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					type: BUSINESS_TYPES.RESTAURANT, // Нужно заменить данные
					tabName: pagesLinks.businessTypes.restaurant.text, // Нужно заменить данные
					// icon: RestaurantIcon, // Нужно заменить данные
					bubbleType: BUBBLES_TYPES.SECONDARY,
					cardTitle: (
						<>
					<span className={styles.cardTitle__desktop}>
						Полезное обучение
						<br />с продолжением
					</span>
							<span className={styles.cardTitle__tablet}>
						Полезное обучение
						<br />с продолжением
					</span>
							<span className={styles.cardTitle__mobile}>
						Полезное обучение с продолжением
					</span>
						</>
					), // Для changeTitles
					cardSubtitle: "опыт наших клиентов", // Для changeTitles
					name: "Хисманова Елена, главный бухгалтер кафе «Привет Десерт»",
					details: (
						<>
							Удобно. Обучение в режиме онлайн с возможностью задавать вопросы.
							Уже в процессе обучения Алексей устранил наши недочёты. Мы продолжаем
							общаться после обучения и решаем все возникающие вопросы. Благодарю за
							поддержку в автоматизации процессов.
						</>
					),
					detailsTablet:
						"Удобно. Обучение в режиме онлайн с возможностью задавать вопросы. Уже в процессе обучения Алексей устранил наши недочёты. Мы продолжаем общаться после обучения и решаем все возникающие вопросы. Благодарю за поддержку в автоматизации процессов.",
					detailsMobile:
						"Удобно. Обучение в режиме онлайн с возможностью задавать вопросы. Уже в процессе обучения Алексей устранил наши недочёты. Мы продолжаем общаться после обучения и решаем все возникающие вопросы. Благодарю за поддержку в автоматизации процессов.",
					// photoCaption: "«Здесь должна быть цитата»", // Нужно заменить данные
					// logo: (
					// 	<StaticImage
					// 		className={componentStyles.businessLogo}
					// 		src="../../../components/_V2/BusinessTypes/assets/images/logo/show-room.png"
					// 		alt="Логотип проекта «Шоу'рма»"
					// 		objectFit="contain"
					// 		placeholder="blurred"
					// 		quality={90}
					// 	/>
					// ),
					photo: (
						<StaticImage
							className={classNames(componentStyles.businessMainImageNew)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/privet-desert.png"
							alt="Елена Хисманова"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
			],
		}
	}

	return {
		title: "Решения на основе данных",
		subTitle: "системный подход",
		readMore: "Смотреть интервью",
		theme: "white",
		changeTitles: true, // Меняет тайтлы карточки, когда переключаешь слайды
		businessType: [
			{
				type: BUSINESS_TYPES.COFFEE_SHOP, // Нужно заменить данные
				tabName: pagesLinks.businessTypes.coffeehouse.text, // Нужно заменить данные
				// icon: CoffeeIcon, // Нужно заменить данные
				name: "Анастасия Филлипова, основатель стрит-фуд проекта «Шоу'рма»",
				cardTitle: (
					<>
					<span className={styles.cardTitle__desktop}>
						Развиваем франшизу
						<br />и ставим сети
					</span>
						<span className={styles.cardTitle__tablet}>
						Развиваем франшизу
						<br />и ставим сети
					</span>
						<span className={styles.cardTitle__mobile}>Ставим сети</span>
					</>
				), // Для changeTitles
				cardSubtitle: "опыт наших клиентов", // Для changeTitles
				details: (
					<>
						Quick Resto сегодня самая сильная и удобная платформа для рестораторов
						созданная на российском рынке. Для нашей сети было очень важно перейти
						на систему с опционалом не хуже передовых
						иностранных аналогов и Quick Resto оправдали наши ожидания.
					</>
				),
				detailsTablet:
					"Quick Resto сегодня самая сильная и удобная платформа для рестораторов созданная на российском рынке. Для нашей сети было очень важно перейти на систему с опционалом не хуже передовых иностранных аналогов и Quick Resto оправдали наши ожидания.",
				detailsMobile:
					"Quick Resto сегодня самая сильная и удобная платформа для рестораторов созданная на российском рынке. Для нашей сети было очень важно перейти на систему с опционалом не хуже передовых иностранных аналогов и Quick Resto оправдали наши ожидания.",
				// photoCaption: "«Здесь должна быть цитата»", // Нужно заменить данные
				// logo: (
				// 	<StaticImage
				// 		className={componentStyles.businessLogo}
				// 		src="../../../components/_V2/BusinessTypes/assets/images/logo/dobro-coffe.png"
				// 		alt="Логотип компании DO.BRO Coffee"
				// 		objectFit="contain"
				// 		placeholder="blurred"
				// 		quality={90}
				// 	/>
				// ),
				photo: (
					<StaticImage
						className={classNames(componentStyles.businessMainImageNew)}
						src="../../../components/_V2/BusinessTypes/assets/images/persons/show-room-2.png"
						alt="Анастасия Филлипова"
						objectFit="cover"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				type: BUSINESS_TYPES.RESTAURANT, // Нужно заменить данные
				tabName: pagesLinks.businessTypes.restaurant.text, // Нужно заменить данные
				// icon: RestaurantIcon, // Нужно заменить данные
				bubbleType: BUBBLES_TYPES.SECONDARY,
				cardTitle: (
					<>
					<span className={styles.cardTitle__desktop}>
						Всегда прибыльно,
						<br />
						даже в межсезонье
					</span>
						<span className={styles.cardTitle__tablet}>
						Всегда прибыльно,
						<br />
						даже в межсезонье
					</span>
						<span className={styles.cardTitle__mobile}>Всегда прибыльно</span>
					</>
				), // Для changeTitles
				cardSubtitle: "опыт наших клиентов", // Для changeTitles
				name: "Кристиан Ленешмидт, владелец ресторана «Брецель»",
				details: (
					<>
						Лучше всего мы зарабатыаем во время туристического сезона.
						<br />А как получать прибыль и привлекать гостей в межсезонье нам
						рассказали эксперты Quick Resto. За время обучения мы автоматизировали
						работу склада и кухни, благодаря этому увеличили скорость
						обслуживания.
					</>
				),
				detailsTablet:
					"Лучше всего мы зарабатыаем во время туристического сезона. А как получать прибыль и привлекать гостей в межсезонье нам рассказали эксперты Quick Resto. За время обучения мы автоматизировали работу склада и кухни, благодаря этому увеличили скорость обслуживания.",
				detailsMobile:
					"Лучше всего мы зарабатыаем во время туристического сезона. А как получать прибыль и привлекать гостей в межсезонье нам рассказали эксперты Quick Resto. За время обучения мы автоматизировали работу склада и кухни, благодаря этому увеличили скорость обслуживания.",
				// photoCaption: "«Здесь должна быть цитата»", // Нужно заменить данные
				// logo: (
				// 	<StaticImage
				// 		className={componentStyles.businessLogo}
				// 		src="../../../components/_V2/BusinessTypes/assets/images/logo/show-room.png"
				// 		alt="Логотип проекта «Шоу'рма»"
				// 		objectFit="contain"
				// 		placeholder="blurred"
				// 		quality={90}
				// 	/>
				// ),
				photo: (
					<StaticImage
						className={classNames(componentStyles.businessMainImageNew)}
						src="../../../components/_V2/BusinessTypes/assets/images/persons/brezel.png"
						alt="Кристиан Ленешмидт"
						objectFit="cover"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				type: BUSINESS_TYPES.RESTAURANT, // Нужно заменить данные
				tabName: pagesLinks.businessTypes.restaurant.text, // Нужно заменить данные
				// icon: RestaurantIcon, // Нужно заменить данные
				bubbleType: BUBBLES_TYPES.SECONDARY,
				cardTitle: (
					<>
					<span className={styles.cardTitle__desktop}>
						Полезное обучение
						<br />с продолжением
					</span>
						<span className={styles.cardTitle__tablet}>
						Полезное обучение
						<br />с продолжением
					</span>
						<span className={styles.cardTitle__mobile}>
						Полезное обучение с продолжением
					</span>
					</>
				), // Для changeTitles
				cardSubtitle: "опыт наших клиентов", // Для changeTitles
				name: "Хисманова Елена, главный бухгалтер кафе «Привет Десерт»",
				details: (
					<>
						Удобно. Обучение в режиме онлайн с возможностью задавать вопросы.
						Уже в процессе обучения Алексей устранил наши недочёты. Мы продолжаем
						общаться после обучения и решаем все возникающие вопросы. Благодарю за
						поддержку в автоматизации процессов.
					</>
				),
				detailsTablet:
					"Удобно. Обучение в режиме онлайн с возможностью задавать вопросы. Уже в процессе обучения Алексей устранил наши недочёты. Мы продолжаем общаться после обучения и решаем все возникающие вопросы. Благодарю за поддержку в автоматизации процессов.",
				detailsMobile:
					"Удобно. Обучение в режиме онлайн с возможностью задавать вопросы. Уже в процессе обучения Алексей устранил наши недочёты. Мы продолжаем общаться после обучения и решаем все возникающие вопросы. Благодарю за поддержку в автоматизации процессов.",
				// photoCaption: "«Здесь должна быть цитата»", // Нужно заменить данные
				// logo: (
				// 	<StaticImage
				// 		className={componentStyles.businessLogo}
				// 		src="../../../components/_V2/BusinessTypes/assets/images/logo/show-room.png"
				// 		alt="Логотип проекта «Шоу'рма»"
				// 		objectFit="contain"
				// 		placeholder="blurred"
				// 		quality={90}
				// 	/>
				// ),
				photo: (
					<StaticImage
						className={classNames(componentStyles.businessMainImageNew)}
						src="../../../components/_V2/BusinessTypes/assets/images/persons/privet-desert.png"
						alt="Елена Хисманова"
						objectFit="cover"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
		],
	}
}

