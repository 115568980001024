import { Data } from "../../../components/_V2/BusinessTypes/types"
import {getData as getBusinessTypesThird }  from "./business-types-third"
import {Locales} from "../../../../localization/types";


export const getData = ((locale: Locales) => {
	return {
		...getBusinessTypesThird(locale),
		type: "compact",
	}
})
