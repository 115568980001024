import * as React from "react"

const MegaphoneIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="25"
		height="24"
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_11756_269058)">
			<g clipPath="url(#clip1_11756_269058)">
				<path
					d="M6.69078 11.8731L12.9629 18.1963M12.1427 6.69664L12.4726 2.375M18.574 12.9277L23.0953 12.7997M15.6417 9.20337L19.9579 4.91647M8.01437 7.90235L16.972 16.86L3.53555 21.3388L8.01437 7.90235Z"
					stroke="#7D828C"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_11756_269058">
				<rect
					width="24"
					height="24"
					fill="white"
					transform="translate(0.89209)"
				/>
			</clipPath>
			<clipPath id="clip1_11756_269058">
				<rect
					width="24"
					height="24"
					fill="white"
					transform="translate(0.890137)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default MegaphoneIcon
