import * as React from "react"
import { SVGProps } from "react"

const CardIconTwo = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="151"
		height="150"
		viewBox="0 0 151 150"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M88.6393 68.6313L113.635 43.6353C116.821 40.45 116.821 35.2855 113.635 32.1002C110.45 28.9148 105.285 28.9149 102.1 32.1002L54.7119 82.3162M95.6874 84.6534L105.476 74.8652M95.6874 84.6534C92.5021 87.8387 87.3376 87.8387 84.1523 84.6534C80.967 81.4681 80.967 76.3036 84.1523 73.1183L93.9405 63.3301C97.1258 60.1448 102.29 60.1448 105.476 63.3301C108.661 66.5154 108.661 71.6799 105.476 74.8652M95.6874 84.6534C92.5021 87.8387 92.502 93.0031 95.6873 96.1884C98.8727 99.3738 104.037 99.3738 107.222 96.1884M105.476 74.8652C108.661 71.6799 113.825 71.6798 117.011 74.8651C120.196 78.0505 120.196 83.2149 117.011 86.4002L107.222 96.1884M107.222 96.1884L112.484 90.9273C115.669 87.742 120.833 87.7419 124.019 90.9273C127.204 94.1126 127.204 99.2771 124.019 102.462L118.758 107.724C115.572 110.909 110.408 110.909 107.222 107.724C104.037 104.538 104.037 99.3738 107.222 96.1884ZM109.821 115.916L95.4104 131.071C93.5347 132.946 90.9908 134 88.3383 134H51.3624C48.7099 134 46.166 132.946 44.2903 131.071L25.8175 109.871C25.3837 109.374 25.2236 108.694 25.3895 108.055L39.1499 55.0697C40.3499 50.0128 43.4665 46.0393 48.5091 46.296C51.4523 46.4458 54.0527 48.0268 55.5688 50.4049C56.7154 52.2034 56.6973 54.4459 56.5451 56.5733L54.7119 82.2048L61.468 89.1236C66.3564 94.1297 68.2731 101.326 66.5224 108.101L66.2704 109.076"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M68.524 38.0034V14"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M77.7058 38.0032L86.7071 29.002"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M59.3416 38.0032L50.3403 29.002"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default CardIconTwo
