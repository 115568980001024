import * as React from "react"
import { SVGProps } from "react"

const SuitcaseIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="25"
		height="24"
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_11756_269064)">
			<path
				d="M22.5796 8.125V19.75C22.5796 20.8201 21.7121 21.6875 20.6421 21.6875H5.14209C4.07204 21.6875 3.20459 20.82 3.20459 19.75V8.125M22.5796 8.125C22.5796 7.05495 21.7121 6.1875 20.6421 6.1875H5.14209C4.07204 6.1875 3.20459 7.05495 3.20459 8.125M22.5796 8.125V12C22.5796 13.0701 21.7121 13.9375 20.6421 13.9375H5.14209C4.07204 13.9375 3.20459 13.0701 3.20459 12V8.125M9.01709 12V15.875M16.7671 12V15.875M16.7671 6.1875V3.28125C16.7671 2.74622 16.3334 2.3125 15.7983 2.3125H9.98584C9.45081 2.3125 9.01709 2.74622 9.01709 3.28125V6.1875H16.7671Z"
				stroke="#7D828C"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_11756_269064">
				<rect
					width="24"
					height="24"
					fill="white"
					transform="translate(0.89209)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default SuitcaseIcon
