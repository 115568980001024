import React from 'react'

import DiscountTimeIcon from '../../../../assets/_V2/svg/DiscountTimeIcon'
import SellingPlanIcon from '../../../../assets/_V2/svg/SellingPlanIcon'
import MegaphoneIcon from '../../../../assets/_V2/svg/MegaphoneIcon'
import AlarmIcon from '../../../../assets/_V2/svg/AlarmIcon'
import BonnetIcon from '../../../../assets/_V2/svg/BonnetIcon'
import SuitcaseIcon from '../../../../assets/_V2/svg/SuitcaseIcon'

import styles from './styles.module.scss'
import {Locales} from "../../../../localization/types";

export const getHeadingAndTextarea = (locale :Locales) => {
  if (locale === "kz-KZ") {
    return {
      heading: 'Quick Resto сіздің командаңызда',
      textarea:
          'Бізбен бірге мекемеңізде бәрі заң бойынша болады, тағамдардың өзіндік құны және қойманың жұмысы дұрыс теңшелген болады, ал дұрыс жеңілдіктер дұрыс клиенттерді тартып тұрады. ',
      anchors: [
        {
          url: '#anchor-1',
          anchor: {
            icon: <DiscountTimeIcon />,
            text: 'Сатуды бастау',
            styles: styles.anchor,
          },
        },
        {
          url: '#anchor-2',
          anchor: {
            icon: <SellingPlanIcon />,
            text: 'Өсу нүктелерін іздеу',
            styles: styles.anchor,
          },
        },
        {
          url: '#anchor-3',
          anchor: {
            icon: <MegaphoneIcon />,
            text: 'Қонақтарды тарту',
            styles: styles.anchor,
          },
        },
        {
          url: '#anchor-4',
          anchor: {
            icon: <AlarmIcon />,
            text: 'Уақытты басқару',
            styles: styles.anchor,
          },
        },
        {
          url: '#anchor-5',
          anchor: {
            icon: <BonnetIcon />,
            text: 'Оқыту',
            styles: styles.anchor,
          },
        },
        {
          url: '#anchor-6',
          anchor: {
            icon: <SuitcaseIcon />,
            text: 'Процесс',
            styles: styles.anchor,
          },
        },
      ],
    }
  }

  return {
    heading: 'Quick Resto в твоей команде',
    textarea:
        'С нами в твоём заведении всё по закону, корректно настроена себестоимость блюд и работа склада, а правильные скидки привлекают правильных клиентов.',
    anchors: [
      {
        url: '#anchor-1',
        anchor: {
          icon: <DiscountTimeIcon />,
          text: 'Старт продаж',
          styles: styles.anchor,
        },
      },
      {
        url: '#anchor-2',
        anchor: {
          icon: <SellingPlanIcon />,
          text: 'Поиск точек роста',
          styles: styles.anchor,
        },
      },
      {
        url: '#anchor-3',
        anchor: {
          icon: <MegaphoneIcon />,
          text: 'Привлечение гостей',
          styles: styles.anchor,
        },
      },
      {
        url: '#anchor-4',
        anchor: {
          icon: <AlarmIcon />,
          text: 'Управление временем',
          styles: styles.anchor,
        },
      },
      {
        url: '#anchor-5',
        anchor: {
          icon: <BonnetIcon />,
          text: 'Обучение',
          styles: styles.anchor,
        },
      },
      {
        url: '#anchor-6',
        anchor: {
          icon: <SuitcaseIcon />,
          text: 'Процесс',
          styles: styles.anchor,
        },
      },
    ],
  }
}
export default {
  heading: 'Quick Resto в твоей команде',
  textarea:
		'С нами в твоём заведении всё по закону, корректно настроена себестоимость блюд и работа склада, а правильные скидки привлекают правильных клиентов.',
  anchors: [
    {
      url: '#anchor-1',
      anchor: {
        icon: <DiscountTimeIcon />,
        text: 'Старт продаж',
        styles: styles.anchor,
      },
    },
    {
      url: '#anchor-2',
      anchor: {
        icon: <SellingPlanIcon />,
        text: 'Поиск точек роста',
        styles: styles.anchor,
      },
    },
    {
      url: '#anchor-3',
      anchor: {
        icon: <MegaphoneIcon />,
        text: 'Привлечение гостей',
        styles: styles.anchor,
      },
    },
    {
      url: '#anchor-4',
      anchor: {
        icon: <AlarmIcon />,
        text: 'Управление временем',
        styles: styles.anchor,
      },
    },
    {
      url: '#anchor-5',
      anchor: {
        icon: <BonnetIcon />,
        text: 'Обучение',
        styles: styles.anchor,
      },
    },
    {
      url: '#anchor-6',
      anchor: {
        icon: <SuitcaseIcon />,
        text: 'Процесс',
        styles: styles.anchor,
      },
    },
  ],
}
