import * as React from "react"
import { SVGProps } from "react"

const SellingPlanIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="25"
		height="24"
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_11756_269055)">
			<rect
				x="3.89209"
				y="4"
				width="18"
				height="17"
				rx="2.07407"
				stroke="#7D828C"
				strokeWidth="1.84809"
				strokeLinecap="round"
			/>
			<path
				d="M13.011 5.36062L13.011 2.6875"
				stroke="#7D828C"
				strokeWidth="1.78208"
				strokeLinecap="round"
			/>
			<path
				d="M18.3577 5.36062L18.3577 2.6875"
				stroke="#7D828C"
				strokeWidth="1.78208"
				strokeLinecap="round"
			/>
			<path
				d="M7.66485 5.36062L7.66485 2.6875"
				stroke="#7D828C"
				strokeWidth="1.78208"
				strokeLinecap="round"
			/>
			<path
				d="M7.3458 15.731L10.7231 12.3536L13.2561 14.8866L17.0867 11.1238"
				stroke="#7D828C"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15.0039 9.89277H18.3812V13.2701"
				stroke="#7D828C"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_11756_269055">
				<rect
					width="24"
					height="24"
					fill="white"
					transform="translate(0.89209)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default SellingPlanIcon
