import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import styles from "./styles.module.scss"
import {Locales} from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-BY') {
		return [
			{
				category: "консультации",
				title: "Назначь встречу \nэксперту",
				description: (
					<p className={styles.consultationDesc}>
						Обучение через разбор кейсов, настройка отчётов для&nbsp;оценки рентабельности бизнеса.
					</p>
				),
				quote: (
					<>
						Не&nbsp;трать время
						<br />
						на&nbsp;исправление ошибок.
						<br />
						Посвяти время бизнесу!
					</>
				),
				img: (
					<StaticImage
						className={styles.consultationImage}
						src="./assets/consultation.png"
						alt="Алексей Гаврилов"
						objectFit="contain"
						objectPosition="top"
						placeholder="blurred"
						quality={90}
					/>
				),

				className: styles.consultationFeature,
				theme: "secondary",
				actionText: "Отправить заявку",
				actionLink: "#popup",
				actionClass: styles.consultationAction,
				reverse: true
			},
		]
	}

	if (locale === 'kz-KZ') {
		return [
			{
				category: "консультации",
				title: "Сарапшымен кездесуді тағайындаңыз",
				description: (
					<p className={styles.consultationDesc}>
						Кейстерді талдау арқылы оқыту, бизнестің табыстылығын бағалау үшін есептерді теңшеу.
					</p>
				),
				quote: (
					<>
						Қателерді түзетуге <br />
						уақытты жұмсамаңыз. <br />
						Бизнеске уақытты бөліңіз!
					</>
				),
				img: (
					<StaticImage
						className={styles.consultationImage}
						src="./assets/consultation.png"
						alt="Алексей Гаврилов"
						objectFit="contain"
						objectPosition="top"
						placeholder="blurred"
						quality={90}
					/>
				),

				className: styles.consultationFeature,
				theme: "secondary",
				actionText: "Өтінім жіберу",
				actionLink: "#popup",
				actionClass: styles.consultationAction,
				reverse: true
			},
		]
	}

	if (locale === 'ru-KZ') {
		return [
			{
				category: "консультации",
				title: "Назначь встречу \nс экспертом",
				description: (
					<p className={styles.consultationDesc}>
						Обучение через разбор кейсов, настройка отчётов для&nbsp;оценки рентабельности бизнеса.
					</p>
				),
				quote: (
					<>
						Не&nbsp;трать время
						<br />
						на&nbsp;исправление ошибок.
						<br />
						Посвяти время бизнесу!
					</>
				),
				img: (
					<StaticImage
						className={styles.consultationImage}
						src="./assets/consultation.png"
						alt="Алексей Гаврилов"
						objectFit="contain"
						objectPosition="top"
						placeholder="blurred"
						quality={90}
					/>
				),

				className: styles.consultationFeature,
				theme: "secondary",
				actionText: "Отправить заявку",
				actionLink: "#popup",
				actionClass: styles.consultationAction,
				reverse: true
			},
		]
	}

	return [
		{
			category: "консультации",
			title: "Забронируй встречу с экспертом",
			description: (
				<p className={styles.consultationDesc}>
					Обучение через разбор кейсов, настройка отчётов для оценки рентабельности бизнеса.
				</p>
			),
			quote: (
				<>
					Не&nbsp;трать время
					<br />
					на&nbsp;исправление ошибок.
					<br />
					Посвяти время бизнесу!
				</>
			),
			img: (
				<StaticImage
					className={styles.consultationImage}
					src="./assets/consultation.png"
					alt="Алексей Гаврилов"
					objectFit="contain"
					objectPosition="top"
					placeholder="blurred"
					quality={90}
				/>
			),

			className: styles.consultationFeature,
			theme: "secondary",
			actionText: "Отправить заявку",
			actionLink: "#popup",
			actionClass: styles.consultationAction,
			reverse: true
		},
	]
}
