import * as React from "react"
import { SVGProps } from "react"

const Card1 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="201"
		height="201"
		viewBox="0 0 201 201"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_17957_1295)">
			<path
				d="M90.8386 37.5054C92.2033 39.9003 92.2846 42.9911 91.0284 45.4584C89.7361 47.9979 87.061 49.7874 84.2141 50.0133C84.2141 50.0133 84.178 50.0133 84.1328 50.0043"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M90.7032 37.2617C90.7484 37.3431 90.7935 37.4335 90.8387 37.5058C91.8509 39.1867 94.2007 39.9459 95.9901 39.1235C97.8699 38.2649 98.8279 35.8157 98.0416 33.9088C102.777 36.2586 107.124 39.3313 111.706 41.9703C116.279 44.6183 121.223 46.8867 126.483 47.4018C128.173 47.5735 129.908 47.5374 131.607 47.2753C135.186 46.7421 138.584 45.2419 141.051 42.6391C144.847 38.6174 146.907 32.1194 146.636 26.5975C146.41 21.8166 145.416 18.0209 141.883 12.6436C141.883 12.6436 139.921 20.0272 136.785 21.2111C137.68 15.7976 133.776 13.8365 133.776 13.8365C133.776 13.8365 133.622 19.1054 123.808 19.494C121.557 19.5844 119.442 18.78 117.418 17.7949C113.044 15.6802 108.263 14.2432 103.392 14.2432C98.5206 14.2432 93.5771 15.7705 89.935 18.9969C90.1338 17.2888 89.221 15.4723 87.6575 14.7583C86.1031 14.0534 84.16 14.6951 83.4551 16.2495C82.8134 17.6684 84.7655 20.3887 84.7655 20.3887L83.1026 19.0331C82.217 18.3553 81.3222 17.7407 80.2468 17.4696C78.4845 17.0267 76.4872 17.325 75.0864 18.4908C73.6946 19.6567 73.0529 21.7443 73.785 23.4072C74.526 25.0701 76.7221 25.992 78.3308 25.1515C74.5893 27.7995 71.9684 31.9838 71.2274 36.5026C70.893 38.5451 70.9472 40.6418 71.3448 42.6662C71.6973 44.4556 72.3299 46.1998 73.2246 47.7995C73.7307 48.7213 74.4085 49.2997 74.4085 49.2997C74.4085 49.2997 74.4357 49.2997 74.4899 49.3087"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M85.6062 53.5742C85.2357 50.8991 83.3739 48.3957 80.8073 47.5733C78.2406 46.7509 75.0865 47.8444 73.8484 50.2484C72.8995 52.083 73.1344 54.3786 74.0924 56.2041C75.0504 58.0297 76.6681 59.4396 78.4123 60.5422"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M86.1304 98.8611C87.0341 95.9872 89.5194 93.7007 92.4114 92.9054H92.4205C92.9808 92.7427 93.5592 92.6433 94.1466 92.6072C95.9632 92.5077 97.8159 92.9506 99.5782 92.4987C101.34 92.0468 102.94 90.1218 102.127 88.4951C101.512 87.3021 99.9306 86.9677 98.5931 87.1304C97.2646 87.3021 95.9722 87.8173 94.6256 87.7721C91.8059 87.6817 89.5014 84.6089 90.1972 81.8706C90.7937 79.5208 93.1887 77.9393 95.6107 77.7043C98.0237 77.4693 100.419 78.346 102.542 79.5208C104.061 80.3523 105.489 81.3464 107.079 82.0333C108.661 82.7201 110.468 83.0816 112.122 82.5845C113.93 82.0333 115.303 80.5421 116.252 78.9153C116.252 78.9063 116.261 78.9063 116.261 78.9063"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M119.316 72.6437C120.888 70.0138 122.976 67.7273 125.055 65.4679C126.374 64.04 127.486 62.7205 128.372 61.3197C129.104 60.199 129.682 59.0242 130.107 57.7137C131.227 54.2524 131.788 50.4566 131.607 47.2754"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M106.898 82.395C104.793 83.8049 103.066 85.775 101.946 88.0435"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M114.499 65.4858C114.499 67.4379 116.008 69.0014 117.4 70.3751C118.792 71.7488 120.247 73.3937 120.102 75.3367C119.985 76.9002 118.747 78.2558 117.246 78.7077C115.746 79.1596 114.074 78.8071 112.755 77.9576C111.435 77.1081 110.45 75.8157 109.754 74.4149"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M110.233 54.6048C109.474 52.5081 107.612 50.8361 105.443 50.33C103.274 49.8239 100.861 50.4746 99.2437 52.0201"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M118.276 57.4965C119.686 57.0446 121.286 57.2344 122.542 58.0116C123.798 58.7888 124.693 60.1354 124.919 61.5905"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M119.135 67.6459C120.183 67.6459 121.033 66.7961 121.033 65.748C121.033 64.6998 120.183 63.8501 119.135 63.8501C118.087 63.8501 117.237 64.6998 117.237 65.748C117.237 66.7961 118.087 67.6459 119.135 67.6459Z"
				fill="#2A2D33"
			/>
			<path
				d="M104.621 63.66C105.669 63.66 106.519 62.8103 106.519 61.7621C106.519 60.714 105.669 59.8643 104.621 59.8643C103.573 59.8643 102.723 60.714 102.723 61.7621C102.723 62.8103 103.573 63.66 104.621 63.66Z"
				fill="#2A2D33"
			/>
			<path
				d="M92.4111 92.9058H92.4202C93.7396 94.7223 95.258 96.4033 96.948 97.8945C97.5806 98.4458 98.2313 98.979 98.9091 99.4851C96.4147 102.612 94.7247 106.381 94.056 110.33"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M96.948 97.894C94.0651 99.1683 91.2725 100.641 88.5884 102.295C86.4826 103.597 84.4492 105.007 82.4971 106.525"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M93.7849 110.438C91.9323 107.799 90.188 105.097 88.5884 102.295"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M80.6807 98.2379C82.913 95.5537 83.0033 91.6947 82.4701 88.2424C81.9459 84.79 80.9157 81.3377 81.3314 77.8763C81.7923 74.0173 84.0246 70.3933 83.6721 66.5252C83.5727 65.4588 83.0304 64.2026 81.964 64.1484C81.2772 64.1122 80.6897 64.6093 80.2107 65.0973C77.2735 68.0887 75.2853 71.9929 74.5894 76.1231C75.0594 71.4687 75.0503 66.7692 74.5533 62.1149C74.4538 61.1841 74.3273 60.217 73.8212 59.4217C73.3151 58.6264 72.33 58.0571 71.4353 58.3282C70.3327 58.6535 69.908 59.955 69.682 61.0846C68.7512 65.8113 68.6156 70.6915 69.2844 75.4633"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M69.4289 59.9277C68.5161 58.6444 66.3832 58.8794 65.2897 60.0181C64.1962 61.1568 63.8979 62.8197 63.7353 64.3832C63.0936 70.6914 63.9793 77.1351 66.1483 83.0999"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M67.314 96.2043C63.5634 92.3091 61.1233 87.1848 60.4455 81.8166C60.2647 80.3615 60.2105 78.8432 60.7527 77.4695C61.295 76.0958 62.5241 74.93 63.9882 74.8486"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M65.2627 96.9997C70.6762 96.349 76.3517 97.0088 81.476 98.8795"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M81.476 98.8794C81.6748 109.354 83.2925 119.792 83.1479 130.285C82.9943 140.994 80.7801 152.137 74.0833 160.488C72.33 162.666 70.0345 164.745 67.2419 164.88C64.0697 165.034 61.3856 162.675 59.2708 160.316C47.7299 147.483 41.7471 130.655 37.1108 114.026"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M60.6446 132.11C59.542 120.253 61.1326 108.161 65.2627 96.9995"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M47.9375 83.832C55.6646 87.4832 61.2949 97.1081 62.4608 106.236"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M63.5365 68.2332C62.8948 67.5192 61.4669 66.7149 60.5089 66.986C59.551 67.2571 58.846 68.0434 58.1953 68.7935C56.8397 70.366 55.475 71.9386 54.1194 73.5111C53.3964 74.3425 52.6463 75.2553 52.5288 76.3579C52.4023 77.578 53.0891 78.7257 53.8212 79.7018C55.8094 82.3498 58.3399 84.573 61.2139 86.2088"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.1166 165.884C12.6552 143.163 16.0081 119.458 25.615 98.5904C28.1726 93.0413 31.2363 87.5827 35.7822 83.4977C40.319 79.4037 46.5639 76.8371 52.5739 77.9035"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M115.159 98.2197C115.186 105.893 115.222 113.565 115.249 121.247C108.182 116.042 102.126 109.462 97.5083 101.988"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M128.372 61.3193C131.688 65.9646 131.734 72.2547 130.757 77.9032C129.745 83.7143 127.82 89.426 127.956 95.3185C128.091 101.238 130.306 106.877 131.643 112.643C133.297 119.747 133.622 127.248 131.842 134.324C130.992 137.722 129.601 141.093 127.242 143.642C126.908 144.004 126.555 144.347 126.185 144.672C120.843 149.336 112.502 148.983 106.203 145.748C105.724 145.504 105.263 145.242 104.802 144.971C99.1804 141.672 94.8244 136.539 90.6039 131.514C88.1728 136.177 87.2961 141.636 88.1005 146.832"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M111.824 83.3076C111.381 87.022 111.688 90.8268 112.718 94.4238C113.062 95.6167 113.514 96.8458 114.454 97.6592C115.511 98.581 116.993 98.825 118.394 98.8341C121.864 98.8431 125.317 97.5688 127.956 95.3185"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M119.451 85.2959C118.529 87.9077 118.71 90.8901 119.957 93.3664"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M48.5433 156.584C32.1222 158.798 16.1891 164.564 2.15381 173.367"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M127.242 143.642C133.306 143.462 139.379 143.434 145.443 143.552"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M199.144 148.468C190.631 147.058 182.063 145.956 173.469 145.151"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M104.802 144.971C99.2254 145.459 93.6493 146.082 88.1003 146.832C85.7595 147.149 83.4279 147.483 81.0962 147.845"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M104.765 178.166C105.353 179.359 107.179 179.205 108.254 178.428C109.329 177.65 110.116 176.457 111.318 175.897C112.836 175.192 114.634 175.671 116.144 176.376C117.653 177.081 119.117 178.012 120.771 178.247C122.922 178.545 125.055 177.605 126.871 176.403C128.688 175.201 130.315 173.719 132.24 172.725C133.523 172.065 134.933 171.631 136.171 170.881C137.409 170.131 138.521 168.974 138.701 167.537C138.927 165.721 137.581 164.004 135.927 163.218C134.273 162.431 132.384 162.368 130.559 162.323C128.733 162.278 126.844 162.223 125.19 161.455C121.747 159.847 120.238 155.644 117.02 153.629C114.409 152.002 110.812 152.128 108.335 153.954C107.07 154.876 106.094 156.186 104.711 156.927C102.551 158.084 99.9304 157.623 97.4993 157.334C94.1012 156.927 90.5585 156.936 87.3502 158.129C84.1419 159.322 81.2951 161.871 80.4365 165.188"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M91.2907 180.732C91.2907 184.763 88.959 188.251 85.57 189.905C85.5157 189.932 85.4525 189.959 85.3892 189.986C84.0878 190.601 82.6328 190.935 81.0964 190.935C75.457 190.935 70.8931 186.371 70.8931 180.732C70.8931 178.473 71.6251 176.385 72.8723 174.695C72.8904 174.677 72.9084 174.65 72.9175 174.632C73.1886 174.306 73.4597 173.99 73.7308 173.674C75.5835 171.74 78.2044 170.538 81.0964 170.538C81.25 170.538 81.4037 170.538 81.5483 170.538C86.9708 170.773 91.2907 175.246 91.2907 180.723V180.732Z"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M87.8745 164.447C87.9558 164.41 88.0462 164.392 88.1276 164.365C89.0675 164.076 90.0616 163.922 91.1009 163.922C96.7403 163.922 101.304 168.495 101.304 174.126C101.304 176.566 100.455 178.798 99.0358 180.551C99.0178 180.56 99.0087 180.578 98.9997 180.588C98.8099 180.813 98.6201 181.039 98.4032 181.247C98.1863 181.464 97.9604 181.681 97.7344 181.88"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M97.7344 181.88C97.7344 181.88 87.6305 188.983 85.5699 189.905M85.5699 189.905C85.5157 189.932 85.6332 189.878 85.5699 189.905Z"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M87.7478 164.501C82.2801 167.312 76.7311 169.923 72.8721 174.695"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M98.9997 180.587C100.138 181.862 101.232 183.326 101.096 185.007C100.997 186.164 100.301 187.185 99.4968 188.025C98.6021 188.956 97.5176 189.733 96.2795 190.095C95.0504 190.465 93.6495 190.393 92.547 189.742C91.6432 189.209 90.9744 188.26 90.8208 187.23"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M99.0718 180.551H99.0356"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M173.586 157.66C179.286 157.66 183.907 156.118 183.907 154.216C183.907 152.315 179.286 150.773 173.586 150.773C167.886 150.773 163.265 152.315 163.265 154.216C163.265 156.118 167.886 157.66 173.586 157.66Z"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M183.907 178.084C183.907 179.982 179.289 181.527 173.586 181.527C167.883 181.527 163.265 179.991 163.265 178.084"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M184.304 159.367C185.895 158.509 188.001 159.34 189.049 160.813C190.097 162.286 190.305 164.193 190.251 166.001C190.206 167.456 190.007 168.902 189.673 170.312C189.492 171.062 189.248 171.839 188.724 172.408C188.136 173.032 187.26 173.321 186.401 173.366C185.543 173.412 184.693 173.231 183.853 173.059C183.988 173.122 184.124 173.186 184.259 173.258"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M163.265 154.216C163.284 162.006 163.247 170.095 163.265 177.885"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M183.907 154.216C184.042 162.133 183.771 169.968 183.907 177.885"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M162.334 160.75C158.502 161.5 154.417 160.895 150.956 159.087"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M175.159 131.722C174.381 137.885 173.604 144.049 172.836 150.212"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M150.459 158.608C149.474 149.688 148.688 140.732 148.091 131.776"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M147.73 124.726C147.558 123.66 147.459 122.594 147.54 121.527C147.54 121.482 147.549 121.428 147.558 121.383C147.558 121.247 147.576 121.111 147.594 120.967C147.856 119.015 149.067 116.801 150.839 115.96C151.987 115.418 153.622 115.481 154.879 115.463C159.28 115.4 163.175 115.526 167.576 115.472C169.221 115.445 170.947 115.445 172.402 116.222C174.128 117.144 175.15 119.06 175.466 120.994L175.493 121.184C175.493 121.184 175.493 121.256 175.493 121.292C175.656 122.494 175.583 123.705 175.43 124.907H175.421"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M177.328 130.077C177.59 127.727 177.996 125.549 178.258 123.199"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M145.778 130.176L145.109 123.091"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M75.0954 58.0933C74.7881 58.4457 74.4989 58.8253 74.2368 59.2049C74.2549 59.1958 74.282 59.1868 74.3001 59.1778"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M145.769 130.077C145.769 130.077 145.769 130.14 145.778 130.176C145.859 130.764 146.699 131.315 148.091 131.776C150.866 132.707 155.854 133.33 161.548 133.33C167.242 133.33 172.42 132.689 175.159 131.721C176.532 131.243 177.318 130.682 177.318 130.077"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M147.558 121.383C146.004 121.889 145.109 122.467 145.109 123.091C145.109 123.145 145.118 123.199 145.127 123.254C145.57 124.98 152.818 126.344 161.684 126.344C167.404 126.344 172.447 125.775 175.43 124.907H175.439C176.894 124.483 177.861 123.986 178.159 123.452C178.186 123.398 178.213 123.344 178.231 123.281C178.249 123.217 178.258 123.154 178.258 123.091C178.258 123.028 178.258 122.955 178.222 122.892C178.222 122.865 178.213 122.847 178.204 122.82C177.969 122.259 177.002 121.744 175.502 121.292"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_17957_1295">
				<rect
					width="200"
					height="200"
					fill="white"
					transform="translate(0.39209 0.5)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default Card1
