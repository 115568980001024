import * as React from "react"
import { SVGProps } from "react"

const Card2 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="201"
		height="201"
		viewBox="0 0 201 201"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_17957_3299)">
			<path
				d="M77.8014 56.544C82.8495 51.5842 82.1633 45.9579 77.6642 41.6548C73.1749 46.0363 72.0379 51.6822 77.8014 56.544Z"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M80.5574 53.3673C87.0463 56.1804 92.0061 53.4359 94.3096 47.6625C88.5657 45.1434 82.9099 46.2118 80.5574 53.3673Z"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M83.0304 47.9858C88.4424 43.4301 88.1857 37.7617 84.0334 33.1267C79.2286 37.1581 77.6594 42.6955 83.0304 47.9858Z"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M86.928 44.8665C93.1523 48.2286 98.3277 45.9153 101.121 40.3478C95.6126 37.3484 89.8882 37.9267 86.928 44.8665Z"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M89.049 40.0075C94.4793 35.479 94.2539 29.8134 90.1175 25.1575C85.2851 29.1665 83.6972 34.6948 89.049 40.0075Z"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M92.5288 37.907C99.498 39.1127 103.674 35.2703 104.536 29.1049C98.3512 28.0266 93.1169 30.3889 92.5288 37.907Z"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M146.151 99.5058C155.482 92.4386 155.551 83.0581 149.082 75.04C140.789 81.2839 137.731 90.3017 146.151 99.5058Z"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M146.579 110.028C145.324 100.539 151.274 95.0012 159.978 93.0898"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M153.431 134.562C157.803 133.582 162.753 134.336 167.771 136.414C163.36 148.128 153.49 153.833 140.345 148.422C141.002 146.285 141.806 144.423 142.747 142.795"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M129.783 162.376C141.536 172.07 153.455 169.335 161.669 158.729C151.26 150.104 138.919 148.937 129.783 162.376Z"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M113.355 173.157C122.579 186.488 135.557 187.145 147.035 178.676C138.88 166.825 126.598 162.052 113.355 173.157Z"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M94.0383 179.504C99.9587 195.236 112.858 199.166 126.542 193.53C121.317 179.553 110.153 171.662 94.0383 179.504Z"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M45.3947 194.048C60.7152 200.968 72.6148 194.606 78.2804 180.942C64.6948 174.767 51.2269 177.1 45.3947 194.048Z"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M24.064 179.69C37.6691 189.561 50.6077 185.738 58.9198 173.495C46.8634 164.703 33.1994 164.272 24.064 179.69Z"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.3999 158.881C19.0254 171.535 31.7778 171.603 42.6384 162.792C34.1303 151.549 21.8778 147.412 9.3999 158.881Z"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.58789 135.494C7.73393 149.383 19.0846 152.902 31.1705 147.991C26.6321 135.66 16.8302 128.652 2.58789 135.494Z"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.01093 112.686C2.47162 126.693 11.6071 133.25 23.9184 132.466C23.5361 120.037 16.8315 110.814 2.01093 112.686Z"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.6994 93.4089C5.15108 104.799 10.6598 112.621 20.7754 115.434C23.9513 105.338 21.1381 96.0457 8.6994 93.4089Z"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18.7966 77.1111C12.3273 86.0897 14.866 94.5978 22.9331 100.048C28.6966 92.0983 28.9613 83.1001 18.7966 77.1111Z"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M88.7867 54.48C88.5612 56.6561 88.4926 58.8713 88.0711 61.018C87.6889 62.998 87.3948 63.8899 87.3948 63.8899C87.3752 63.8801 87.3654 63.8801 87.3458 63.8703C83.8955 62.3216 77.9065 62.5079 76.8479 68.5655C76.8283 68.5557 76.8185 68.5459 76.7989 68.5361C71.9273 65.9778 68.1829 61.3316 66.7224 56.0189C65.2521 50.6965 66.0951 44.7761 68.9867 40.0613C66.7322 40.7082 64.0955 39.3458 63.3211 37.1403C62.5468 34.9349 63.7426 32.2197 65.8893 31.3081C67.4968 30.6318 69.4572 31.0239 70.7706 32.1413M70.7706 32.1413C71.2215 32.5236 71.6038 32.9843 71.8685 33.5234C71.447 33.1117 71.0745 32.6412 70.7706 32.1413ZM70.7706 32.1413C70.2609 31.2983 69.9571 30.3476 69.9669 29.3772C69.9865 27.8088 70.9765 26.2209 72.4762 25.7896C73.9759 25.3583 76.9655 26.9463 77.1223 28.5048C76.7596 24.2703 79.4356 21.3787 82.9251 18.9577C86.8851 16.2033 91.492 15.0075 95.7363 14.8702C104.029 14.6056 103.372 15.1545 107.361 13.9488C111.429 12.7138 115.311 9.96924 116.614 5.92102C118.879 8.25389 120.065 11.5964 119.761 14.8408C123.505 12.6256 126.211 8.72438 126.975 4.44092C131.886 10.3123 134.385 18.1441 133.778 25.77C133.494 29.3478 132.484 33.0039 130.102 35.6896C127.946 38.1107 124.927 39.483 121.702 40.1593C120.192 40.4926 118.634 40.669 117.105 40.7279C112.331 40.9141 106.961 40.5454 100.482 40.1729"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M86.9146 63.5073C88.0908 64.4287 89.0024 65.6834 89.5121 67.0948"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M76.9749 67.791C76.1908 70.2513 76.8181 73.1037 78.5629 75.0053C80.2978 76.9069 83.0914 77.789 85.6007 77.2303"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M118.223 75.7052C118.213 78.3909 118.399 80.7592 118.546 83.4253C112.115 84.0625 104.931 84.1017 100.598 79.1811"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M121.699 40.1592C122.984 44.6583 124.238 49.3534 123.336 53.9408C123.268 54.3034 123.18 54.6759 123.082 55.0288C122.346 57.7929 120.974 60.3709 120.033 63.0958C119.543 64.5269 119.011 66.9283 118.746 68.4084"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M105.489 102.608C105.195 100.157 106.362 97.746 107.901 95.8248C109.44 93.9036 111.351 92.2961 112.919 90.3945C114.488 88.493 115.203 86.1111 115.556 83.6802"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M114.379 64.1456C114.447 65.4297 115.359 66.5177 116.388 67.2823C117.417 68.0468 118.594 68.6153 119.564 69.4583C120.534 70.3013 121.289 71.5559 121.073 72.8204C120.819 74.2809 119.368 75.2415 117.927 75.5649C115.614 76.0844 113.055 75.3591 111.369 73.683"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M104.46 62.2974C105.426 62.2974 106.21 61.5139 106.21 60.5474C106.21 59.5809 105.426 58.7974 104.46 58.7974C103.493 58.7974 102.71 59.5809 102.71 60.5474C102.71 61.5139 103.493 62.2974 104.46 62.2974Z"
				fill="#2A2D33"
			/>
			<path
				d="M115.32 114.082C116.287 114.082 117.07 113.298 117.07 112.332C117.07 111.365 116.287 110.582 115.32 110.582C114.354 110.582 113.57 111.365 113.57 112.332C113.57 113.298 114.354 114.082 115.32 114.082Z"
				fill="#2A2D33"
			/>
			<path
				d="M73.2402 109.789C74.2067 109.789 74.9902 109.005 74.9902 108.039C74.9902 107.072 74.2067 106.289 73.2402 106.289C72.2737 106.289 71.4902 107.072 71.4902 108.039C71.4902 109.005 72.2737 109.789 73.2402 109.789Z"
				fill="#2A2D33"
			/>
			<path
				d="M116.767 61.8218C117.734 61.8218 118.517 61.0383 118.517 60.0718C118.517 59.1053 117.734 58.3218 116.767 58.3218C115.801 58.3218 115.017 59.1053 115.017 60.0718C115.017 61.0383 115.801 61.8218 116.767 61.8218Z"
				fill="#2A2D33"
			/>
			<path
				d="M125.24 54.1074C122.682 55.5483 120.829 55.5287 117.898 54.9504"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M110.89 52.9019C109.459 56.1267 102.225 54.9211 99.7451 54.5192"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M79.3315 92.0857C81.0175 86.9102 81.4947 83.0035 81.6711 77.5732"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M80.2097 88.7676C70.5646 89.2675 61.0272 91.8846 52.4701 96.3641C53.813 98.001 55.1362 100.04 55.8028 102.235C58.2729 110.263 60.1843 118.664 59.2825 127.015C58.3807 135.366 54.2345 143.727 46.9712 147.942"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M47.0102 139.189C46.3829 148.422 46.0987 157.675 46.1575 166.938"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M34.6895 155.166C38.0515 148.157 43.0015 141.923 49.0592 137.071"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M57.136 135.67C44.3444 130.887 33.7681 120.487 28.7593 107.783C34.8365 101.206 43.5407 97.1384 52.4899 96.6875"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M22.2803 121.114C24.2309 117.477 26.848 114.194 29.965 111.479"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M73.4658 89.4629C75.9261 92.8544 77.6806 96.5203 78.8275 100.559C79.4744 102.823 79.9057 105.205 79.543 107.528C79.1803 109.851 77.9355 112.135 75.8771 113.292C74.397 114.125 72.564 114.311 70.9467 113.782C69.3293 113.262 67.9473 112.047 67.2317 110.498C66.8004 109.586 66.5456 108.489 65.6928 107.94C65.1047 107.557 64.3597 107.528 63.654 107.548C61.5956 107.597 59.5372 107.891 57.5474 108.44"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M108.547 95.0503C110.371 96.8931 112.576 97.5204 114.87 98.726C117.163 99.9317 119.937 100.628 120.565 103.137C120.966 104.754 120.623 107.42 120.594 109.097C120.545 112.194 121.123 115.242 121.858 118.252C124.681 129.808 122.574 142.492 116.144 152.509C116.203 158.028 114.36 163.498 111.361 168.124"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M120.555 113.635C119.878 114.693 119.153 115.742 118.192 116.546C117.232 117.35 116.016 117.918 114.762 117.869C113.154 117.801 111.723 116.703 110.89 115.33C110.057 113.958 109.763 112.321 109.645 110.714C109.292 105.911 110.459 101.039 109.665 96.2852C109.694 96.5204 109.733 96.7655 109.763 97.0007"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M79.3179 110.018C83.768 113.478 89.0218 116.125 94.6384 116.615C100.167 117.085 106.058 115.233 109.675 111.028"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M116.575 151.774C110.635 154.47 103.48 154.352 97.6279 151.441"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M91.5505 147.854C87.4827 151.765 81.6309 153.411 75.9751 153.411C70.3292 153.411 64.7911 151.921 59.3804 150.294"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M94.6581 128.485L94.5503 140.522"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M92.0506 160.4C92.1388 163.478 91.2762 166.576 89.6099 169.163"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M167.899 92.9233C169.242 99.8337 170.584 106.744 171.927 113.655C172.123 114.674 172.319 115.742 172.035 116.742C171.702 117.889 170.79 118.761 169.918 119.575C162.811 126.191 155.705 132.817 148.187 138.953C144.296 142.129 139.718 145.335 134.729 144.717C132.063 144.384 129.632 142.962 127.652 141.139C125.672 139.316 124.103 137.111 122.545 134.925"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M160.488 99.7456C153.362 106.038 145.295 111.263 135.748 116.056C131.367 111.479 124.819 106.529 120.183 102.206"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M135.62 116.056C133.444 122.3 128.543 127.71 123.093 131.455"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M163.331 104.127C161.517 101.98 160.164 99.3732 159.821 96.5796C159.478 93.786 160.233 90.8062 162.144 88.7282C163.752 86.9835 166.065 85.9935 168.418 85.7386C170.77 85.4838 173.162 85.9444 175.377 86.7776C177.161 87.454 178.945 88.4538 179.906 90.1005C180.866 91.7472 180.68 94.1781 179.092 95.2367C177.837 96.0699 176.161 95.8444 174.701 95.472C171.976 94.776 169.359 93.7076 166.928 92.2961"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M172.025 114.174C172.397 116.468 173.201 120.487 174.848 121.437C176.495 122.388 178.494 122.506 180.396 122.427C183.278 122.3 186.15 121.751 188.875 120.8C190.394 120.271 191.952 119.546 192.815 118.193C193.756 116.703 193.648 114.801 193.501 113.037"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M196.569 110.606C197.5 109.44 197.882 107.705 197.059 106.46C196.5 105.617 195.491 105.146 194.491 105.039C193.491 104.931 192.472 105.127 191.491 105.382C189.629 105.852 187.777 106.509 186.208 107.616C184.64 108.724 183.356 110.341 182.944 112.214C182.728 113.204 182.797 114.36 183.532 115.076C184.209 115.742 185.267 115.831 186.208 115.772C188.384 115.634 190.384 114.87 192.227 113.733C193.707 112.821 195.471 111.969 196.569 110.596V110.606Z"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M193.325 104.921C194.55 104.352 195.785 103.725 196.687 102.725C197.588 101.725 198.098 100.265 197.647 98.9906C197.216 97.7849 196.01 96.991 194.756 96.7459C193.501 96.5009 192.207 96.7263 190.972 97.04C188.767 97.6085 186.64 98.4711 184.679 99.6081C183.209 100.471 181.729 101.627 181.337 103.284C181.052 104.49 181.435 105.823 182.317 106.695C183.199 107.567 184.532 107.94 185.728 107.646"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M192.677 96.7069C194.52 96.256 195.775 95.0798 196.873 93.9133C197.971 92.7469 198.519 90.9139 197.765 89.4926C197.255 88.532 196.226 87.9145 195.167 87.6695C194.109 87.4244 192.991 87.5126 191.913 87.6597C189.835 87.9439 187.767 88.4536 185.875 89.3554C184.434 90.0416 183.081 90.9825 182.189 92.296C181.297 93.6095 180.944 95.364 181.542 96.8343C182.14 98.3046 184.013 99.5103 185.561 99.1378"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M191.139 87.5226L190.992 74.4565C187.875 74.5056 184.885 75.8484 182.405 77.7304C179.925 79.6124 176.799 83.6818 174.888 86.1323"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M178.886 81.0044C177.063 79.9163 174.936 79.3086 172.799 79.044C170.417 78.7499 167.496 78.5343 165.408 78.8969C165.105 78.9459 164.811 79.0146 164.546 79.093C165.193 81.3866 165.84 83.6901 166.487 85.9936"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M188.718 74.8191C188.796 71.3198 188.875 67.7911 188.179 64.3702C187.914 63.0665 187.473 61.6844 186.395 60.9003C185.532 60.2729 184.395 60.1357 183.346 60.3121C182.552 60.4494 181.729 60.7532 180.974 60.4788C180.376 60.2631 179.955 59.7436 179.475 59.3222C178.367 58.3616 176.789 57.9793 175.358 58.3125C173.927 58.6458 172.682 59.6848 172.113 61.0375C171.947 61.4296 171.819 61.8609 171.486 62.1255C170.839 62.645 169.908 62.2137 169.094 62.0863C167.722 61.8707 166.33 62.6254 165.477 63.733C164.625 64.8407 164.262 66.2325 164.085 67.6048C163.605 71.408 164.487 75.2308 165.419 78.9457"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M185.718 75.5245C185.826 72.4467 185.424 69.3493 184.512 66.4087C178.847 68.3201 171.711 71.094 173.309 79.1121C173.358 78.9454 173.368 78.9748 173.417 78.818"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M170.613 78.6417C169.222 75.3482 168.702 71.6921 169.114 68.1438C171.78 67.9968 174.485 68.6535 176.779 70.016"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M182.445 76.76C182.003 75.5641 181.16 74.5055 180.102 73.7998C179.043 73.094 175.985 71.9962 174.368 72.7902"
				stroke="#2A2D33"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_17957_3299">
				<rect
					width="200"
					height="200"
					fill="white"
					transform="translate(0.39209 0.5)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default Card2
