import * as React from "react"
import { SVGProps } from "react"

const AlarmIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="25"
		height="24"
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.8921 20.5042C17.3104 20.5042 20.8921 16.9224 20.8921 12.5042C20.8921 8.08587 17.3104 4.50415 12.8921 4.50415C8.47381 4.50415 4.89209 8.08587 4.89209 12.5042C4.89209 16.9224 8.47381 20.5042 12.8921 20.5042Z"
			stroke="#7D828C"
			strokeWidth="2"
			strokeMiterlimit="10"
			strokeLinecap="round"
		/>
		<path
			d="M12.8921 8.50415V12.5042"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M16.8922 12.5043L12.8922 12.5043"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M3.47791 5.91833L6.30633 3.08991"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M22.2611 5.91858L19.4327 3.09015"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
		/>
	</svg>
)

export default AlarmIcon
