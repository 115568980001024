import React from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"
import {Locales} from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-BY') {
		return {
			heading: (
				<span className={styles.heading}>Опыт есть!</span>
			),
			headingType: "secondary",
			textarea: (
				<>
			<span className={classNames(styles.contentDesktop, styles.textarea)}>
				Мы прошли огонь, воду и медные трубы, пока работали в общепите.
				<br />
				Сегодня делимся опытом и помогаем избежать ошибок, о которых можно
				рассказать только при личной встрече.
			</span>
					<span className={classNames(styles.contentTablet, styles.textarea)}>
				Мы прошли огонь, воду и медные трубы, пока работали в общепите.
				<br />
				Сегодня делимся опытом и помогаем избежать ошибок, о которых можно
				рассказать только при личной встрече.
			</span>
					<span className={classNames(styles.contentMobile, styles.textarea)}>
				Мы прошли огонь, воду и медные трубы, пока работали в общепите. Сегодня
				делимся опытом и помогаем избежать ошибок, о которых можно рассказать
				только при личной встрече.
			</span>
				</>
			),
		}
	}

	if (locale === 'kz-KZ') {
		return {
			heading: (
				<span className={styles.heading}>Біздің тәжірибеміз бар</span>
			),
			headingType: "secondary",
			textarea: (
				<>
			<span className={classNames(styles.contentDesktop, styles.textarea)}>
				Біз қоғамдық тамақтандыруда жұмыс істеген кезде көптеген сынақтардан өттік.
				<br />
				Бүгін тәжірибе алмасамыз және тек жеке кездесу кезінде ғана айтуға болатын қателерге жол бермеуге көмектесеміз.
			</span>
					<span className={classNames(styles.contentTablet, styles.textarea)}>
				Біз қоғамдық тамақтандыруда жұмыс істеген кезде көптеген сынақтардан өттік.
				<br />
				Бүгін тәжірибе алмасамыз және тек жеке кездесу кезінде ғана айтуға болатын қателерге жол бермеуге көмектесеміз.
			</span>
					<span className={classNames(styles.contentMobile, styles.textarea)}>
				Біз қоғамдық тамақтандыруда жұмыс істеген кезде көптеген сынақтардан өттік.
				Бүгін тәжірибе алмасамыз және тек жеке кездесу кезінде ғана айтуға болатын қателерге жол бермеуге көмектесеміз.
			</span>
				</>
			),
		}
	}

	if (locale === 'ru-KZ') {
		return {
			heading: (
				<span className={styles.heading}>У нас есть опыт</span>
			),
			headingType: "secondary",
			textarea: (
				<>
			<span className={classNames(styles.contentDesktop, styles.textarea)}>
				Мы прошли огонь, воду и медные трубы, пока работали в общепите.
				<br />
				Сегодня делимся опытом и помогаем избежать ошибок, о которых можно
				рассказать только при личной встрече.
			</span>
					<span className={classNames(styles.contentTablet, styles.textarea)}>
				Мы прошли огонь, воду и медные трубы, пока работали в общепите.
				<br />
				Сегодня делимся опытом и помогаем избежать ошибок, о которых можно
				рассказать только при личной встрече.
			</span>
					<span className={classNames(styles.contentMobile, styles.textarea)}>
				Мы прошли огонь, воду и медные трубы, пока работали в общепите. Сегодня
				делимся опытом и помогаем избежать ошибок, о которых можно рассказать
				только при личной встрече.
			</span>
				</>
			),
		}
	}
	return {
		heading: (
			<span className={styles.heading}>Плавали, знаем!</span>
		),
		headingType: "secondary",
		textarea: (
			<>
			<span className={classNames(styles.contentDesktop, styles.textarea)}>
				Мы прошли огонь, воду и медные трубы, пока работали в общепите.
				<br />
				Сегодня делимся опытом и помогаем избежать ошибок, о которых можно
				рассказать только при личной встрече.
			</span>
				<span className={classNames(styles.contentTablet, styles.textarea)}>
				Мы прошли огонь, воду и медные трубы, пока работали в общепите.
				<br />
				Сегодня делимся опытом и помогаем избежать ошибок, о которых можно
				рассказать только при личной встрече.
			</span>
				<span className={classNames(styles.contentMobile, styles.textarea)}>
				Мы прошли огонь, воду и медные трубы, пока работали в общепите. Сегодня
				делимся опытом и помогаем избежать ошибок, о которых можно рассказать
				только при личной встрече.
			</span>
			</>
		),
	}
}
