import * as React from "react"
import { SVGProps } from "react"

const CardIconThree = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="151"
		height="150"
		viewBox="0 0 151 150"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect
			x="14.3921"
			y="20.0996"
			width="122"
			height="84.8756"
			rx="11"
			stroke="#3021E5"
			strokeWidth="3"
		/>
		<path
			d="M43.567 129.9H107.219"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M75.3921 105.5L75.3921 129.9"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M57.1133 66.0694V77.8602C57.1133 82.5805 65.0446 86.4071 75.3931 86.4071C85.7416 86.4071 93.7261 82.5805 93.7261 77.8602L93.7259 77.8147L93.7261 66.0694M43.567 53.5003L75.3931 38.668L107.219 53.5003L75.3931 68.3326L43.567 53.5003Z"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default CardIconThree
