import React from 'react'
import classNames from 'classnames'

import DefaultLayout from '../layout/default'

import HeadingTextareaAndAnchors from '../components/_V3/HeadingTextareaAndAnchors'
import HeadingTextareaAndTable from '../components/_V3/HeadingTextareaAndTable'
import HeadingTextareaAndCardListHalfWith from '../components/_V3/HeadingTextareaAndCardListHalfWith'
import HeadingTextareaAndCardList from '../components/_V3/HeadingTextareaAndCardList'
import HeadingAndTextarea from '../components/_V3/HeadingAndTextarea'

import ConsultationFeature from '../pagesSections/service/ConsultationFeature'
import CtaForm from '../pagesSections/index/CtaForm'
import KnowledgeBase from '../pagesSections/index/KnowledgeBase'
import BusinessTypes from '../components/_V2/BusinessTypes'

import Pres from '../pagesSections/index/ProductPresentation/assets/pres.png';
import PresBy from '../pagesSections/index/ProductPresentation/assets/pres_by.png';
import PresKz from '../pagesSections/index/ProductPresentation/assets/pres_kz.png';

import pageStyles from '../styles/_V2/pagesStyles/styles.module.scss'
import styles from './styles/_V2/service.module.scss'

import { getHeadingAndTextarea } from '../pages-data/_V3/service/HeadingTextareaAndAnchors/data'
import { getData as getHeadingTextareaAndTableData } from '../pages-data/_V3/service/HeadingTextareaAndTable/data'
import { getHeadingTextareaAndCardListHalfWith } from '../pages-data/_V3/service/HeadingTextareaAndCardListHalfWith/data'
import { getHeadingTextareaAndCardList } from '../pages-data/_V3/service/HeadingTextareaAndCardList/data'
import { getData as headingAndTextarea } from '../pages-data/_V3/service/HeadingAndTextarea/data'
import { getData as businessTypes } from '../pages-data/_V2/common/business-types-third-compact'
import { externalLinks } from '../pages-data/_V2/common/links'

import { useLocalizationContext } from '../localization/useLocalizationContext'

export default function PageService() {
  const localizationContext = useLocalizationContext()

  const metaTitle = {
    'ru-RU': 'Сервис Quick Resto: настройка процессов в ресторанном бизнесе',
    'ru-KZ': 'Сервис Quick Resto: настройка процессов в ресторанном бизнесе | Казахстан',
    'kz-KZ': 'Сервис Quick Resto: настройка процессов в ресторанном бизнесе | Казахстан',
    'ru-BY': 'Сервис Quick Resto Беларусь: настройка процессов в ресторанном бизнесе',
  }

  const currentTitle = metaTitle[localizationContext.locale]

  const metaUrl = {
    'ru-RU': `${externalLinks.quickrestoRU.href}service/`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}service/`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}service/`,
    'ru-BY': `${externalLinks.quickrestoBY.href}service/`,
  }

  const currentCanonicalLink = metaUrl[localizationContext.locale]

  const metaDescription = {
    'ru-RU': 'Закажите сервисные услуги Quick Resto для бизнеса — проконсультируем вас по услугам и подберём необходимый состав работ. Поставим на учёт фискальный регистратор. Заполним техкарты, настроим корректное списание продуктов и отчёты по продажам. Подключим 1С, Меркурий, ЕГАИС.',
    'ru-KZ': 'Закажи сервисные услуги Quick Resto для бизнеса — проконсультируем по услугам и подберём необходимый состав работ. Поставим на учёт фискальный регистратор. Заполним техкарты, настроим корректное списание продуктов и отчёты по продажам. Подключим 1С и другие сервисы. Автоматизируй бизнес в Казахстане',
    'kz-KZ': 'Закажи сервисные услуги Quick Resto для бизнеса — проконсультируем по услугам и подберём необходимый состав работ. Поставим на учёт фискальный регистратор. Заполним техкарты, настроим корректное списание продуктов и отчёты по продажам. Подключим 1С и другие сервисы. Автоматизируй бизнес в Казахстане',
    'ru-BY': 'Закажи сервисные услуги Quick Resto для бизнеса — проконсультируем по услугам и подберём необходимый состав работ. Поставим на учёт фискальный регистратор. Заполним техкарты, настроим корректное списание продуктов и отчёты по продажам. Подключим 1С и другие сервисы. Автоматизируй бизнес в Беларуси',
  }

  const ogImage = {
    'ru-RU': `${externalLinks.quickrestoRU.href}${Pres}`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}${PresKz}`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}${PresKz}`,
    'ru-BY': `${externalLinks.quickrestoBY.href}${PresBy}`,
  }

  const metaTags = [
    {
      name: 'description',
      content: metaDescription[localizationContext.locale],
    },
    {
      property: 'og:url',
      content: metaUrl[localizationContext.locale],
    },
    {
      property: 'og:title',
      content: currentTitle,
    },
    {
      property: 'og:description',
      content: metaDescription[localizationContext.locale],
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: ogImage[localizationContext.locale]
    },
  ]

  const headingTextareaAndTable = getHeadingTextareaAndTableData(localizationContext.locale)

  return (
    <DefaultLayout
      key={localizationContext.locale}
      title={currentTitle}
      metaTags={metaTags}
      mainWrapperClassName={classNames(
        pageStyles.pageWrapper,
        pageStyles.pageGray,
        pageStyles.servicePage,
      )}
      linkCanonical={currentCanonicalLink}
    >
      <HeadingTextareaAndAnchors
        fixed
        className={styles.service__headingTextareaAndAnchors}
        data={getHeadingAndTextarea(localizationContext.locale)}
      />

      <HeadingTextareaAndTable
        anchor="anchor-1"
        className={classNames(
          pageStyles.pageSection,
          styles.service__headingTextareaAndTable,
        )}
        data={headingTextareaAndTable[0]}
      />

      <HeadingTextareaAndTable
        anchor="anchor-2"
        className={pageStyles.pageSection}
        data={headingTextareaAndTable[1]}
      />

      <HeadingTextareaAndTable
        anchor="anchor-3"
        className={pageStyles.pageSection}
        data={headingTextareaAndTable[2]}
      />

      <HeadingTextareaAndCardListHalfWith
        anchor="anchor-4"
        className={pageStyles.pageSection}
        data={getHeadingTextareaAndCardListHalfWith(localizationContext.locale)}
      />

      <CtaForm isWhiteBackground className={pageStyles.pageSection} />

      <HeadingTextareaAndTable
        className={classNames(
          pageStyles.pageSection,
          styles.service__headingTextareaAndTable2,
          {[styles.service__mobile]: localizationContext.locale !== 'ru-RU'}
        )}
        anchor="anchor-5"
        data={headingTextareaAndTable[3]}
      />

      <HeadingTextareaAndCardList
        anchor="anchor-6"
        className={classNames(
          pageStyles.pageSection,
          styles.service__headingTextareaAndCardList,
        )}
        data={getHeadingTextareaAndCardList(localizationContext.locale)}
      />

      <HeadingAndTextarea
        className={classNames(
          pageStyles.pageSection,
          styles.service__headingAndTextarea,
        )}
        data={headingAndTextarea(localizationContext.locale)}
      />

      <ConsultationFeature className={pageStyles.pageSection} />

      <BusinessTypes
        className={classNames(
          pageStyles.pageSection,
          styles.service__businessTypes,
        )}
        data={businessTypes(localizationContext.locale)}
      />

      <CtaForm isWhiteBackground className={pageStyles.pageSection} />

      <KnowledgeBase
        theme="white"
        type="horizontal"
        className={classNames(
          pageStyles.pageSection,
          styles.service__knowledgeBase,
        )}
      />
    </DefaultLayout>
  )
}
