import React from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"

type TableType = {
	id: number // id таблицы
	rowStyles: string // Стили ряда
	col1: string | JSX.Element // Колонка 1
	col2: string | JSX.Element // Колонка 2
	col3: string | JSX.Element // Колонка 3
}

interface Props {
	className?: string // Класс
	anchor?: string // Якорь
	data: {
		heading: string | JSX.Element // Заголовок
		textarea: string | JSX.Element // Описание
		table: [TableType] // Таблица
	}
}

// Компонент с заголовком, описание и таблицей
// Пример: /service/
export default function HeadingTextareaAndTable(props: Props) {
	const data = props.data || {}

	const renderTable = (): JSX.Element => (
		<>
			<div className={classNames(styles.table, styles.tableDesktop)}>
				{data.table.map((row, idx) => (
					<div
						key={row.id}
						className={classNames(styles.table__row, {
							[styles.table__rowHeadings]: idx === 0,
						}, row.rowStyles)}
					>
						<div className={styles.table__col}>{row.col1}</div>
						<div className={styles.table__col}>{row.col2}</div>
						<div className={styles.table__col}>{row.col3}</div>
					</div>
				))}
			</div>
			<div className={classNames(styles.table, styles.tableMobile)}>
				{data.table.map((row, idx) => (
					<div
						key={row.id}
						className={classNames(styles.table__row, {
							[styles.table__rowHeadings]: idx === 0,
						}, row.rowStyles)}
					>
						<div data-name='firstCol' data-id={idx} className={styles.table__col}>
							{row.col1} {row.col3}
						</div>
						<div data-name='secondCol' data-id={idx} className={styles.table__col}>{row.col2}</div>
					</div>
				))}
			</div>
		</>
	)

	return (
		<section id={props.anchor} className={classNames(styles.section, props.className)}>
			<h2 className={styles.heading}>{data.heading}</h2>
			<p className={styles.textarea}>{data.textarea}</p>

			{data?.table?.length > 0 && renderTable()}
		</section>
	)
}
