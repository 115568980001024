import React from 'react'
import classNames from 'classnames'

import DiscountTimeIcon from '../../../../assets/_V2/svg/DiscountTimeIcon'
import SellingPlanIcon from '../../../../assets/_V2/svg/SellingPlanIcon'
import MegaphoneIcon from '../../../../assets/_V2/svg/MegaphoneIcon'
import BonnetIcon from '../../../../assets/_V2/svg/BonnetIcon'

import styles from './styles.module.scss'
import {Locales} from "../../../localization/types";

export const getData = (locale: Locales) => {
    if (locale === 'ru-BY') {
        return [
            {
                heading: (
                    <span className={classNames(styles.heading, styles.colorBluePrimary)}>
                        <DiscountTimeIcon />
                        Старт продаж
                    </span>
                ),
                textarea: (
                    <>
                        <span className={styles.textarea__desktop}>
                          Запуск заведения — это море работы. Делегируй нам настройку системы,
                          <br />
                          освободи время для ремонта, поиска персонала и поставщиков.
                        </span>
                        <span className={styles.textarea__tablet}>
                          Запуск заведения — это море работы. Делегируй нам настройку системы,
                        <br />
                        освободи время для ремонта, поиска персонала и поставщиков.
                        </span>
                        <span className={styles.textarea__mobile}>
                          Запуск заведения — это море работы. Делегируй нам настройку системы,
                          освободи время для ремонта, поиска персонала и поставщиков.
                        </span>
                    </>
                ),
                table: [
                    {
                        id: 0,
                        col1: 'Услуги',
                        col2: 'Описание',
                        col3: 'Стоимость',
                    },
                    {
                        id: 1,
                        col1: (
                        <span className={styles.col1}>
                            Подключим и&nbsp;настроим <br/> оборудование
                        </span>
                        ),
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorBluePrimary)}>
                                <li>Установим и настроим онлайн-кассу, принтеры, денежный ящик</li>
                                <li>Настроим роутер</li>
                                <li>Подготовим весь комплект оборудования, а при покупке оборудования в нашем магазине сделаем бесплатную доставку</li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorBluePrimary)}>
                                от 50 Р
                            </span>
                        ),
                    },
                    {
                        id: 2,
                        col1: (
                            <span className={styles.col1}>
                                Заполним <br/> номенклатуру
                            </span>
                        ),
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorBluePrimary)}>
                                <li>Добавим в меню ваши блюда и напитки с техкартами.</li>
                                <li>Настроим корректное списание продуктов на складе для точного учета.</li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorBluePrimary)}>
                                от 90 Р
                            </span>
                        ),
                    },
                ],
            },
            {
                heading: (
                    <span className={classNames(styles.heading, styles.colorFuchsia)}>
                        <SellingPlanIcon />
                        Поиск точек роста
                    </span>
                ),
                textarea: (
                    <>
                        <span className={styles.textarea__desktop}>
                          На этапе, когда важно сохранять ресурсы для поиска новых идей и точек
                          роста,
                          <br />
                          мы настроим автоматическую отчётность для аналитики и упростим ряд
                          других задач.
                        </span>
                        <span className={styles.textarea__tablet}>
                          На этапе, когда важно сохранять ресурсы для поиска новых идей и точек
                          роста,
                          <br />
                          мы настроим автоматическую отчётность для аналитики и упростим ряд
                          других задач.
                        </span>
                        <span className={styles.textarea__mobile}>
                          На этапе, когда важно сохранять ресурсы для поиска новых идей и точек
                          роста, мы настроим автоматическую отчётность для аналитики и упростим
                          ряд других задач.
                        </span>
                    </>
                ),
                table: [
                    {
                        id: 0,
                        col1: 'Услуги',
                        col2: 'Описание',
                        col3: 'Стоимость',
                    },
                    {
                        id: 1,
                        col1: (
                        <span className={styles.col1}>
                            Настроим виджеты <br/> для аналитики
                        </span>
                        ),
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorFuchsia)}>
                                <li>
                                    Сделаем отображение нужных показателей продаж: по выручке, блюдам, каждому сотруднику, заведениям. Увидишь, какие каналы нужно подтянуть.
                                </li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorFuchsia)}>
                                от 40 Р
                            </span>
                        ),
                    },
                    {
                        id: 2,
                        col1: (
                            <span className={styles.col1}>
                                Расположим блюда на&nbsp;терминале по&nbsp;маржинальности
                            </span>
                        ),
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorFuchsia)}>
                                <li>
                                    Проанализируем и расположим блюда на терминале так, чтобы на первых позициях стояли более маржинальные. Кассиры смогут предлагать их в первую очередь и увеличивать средний чек.
                                </li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorFuchsia)}>
                                от 50 Р
                            </span>
                        ),
                    },
                    {
                        id: 3,
                        col1: (
                            <span className={styles.col1}>
                                Еженедельно будем вносить приходные накладные
                            </span>
                        ),
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorFuchsia)}>
                                <li>
                                    Покажем, как своевременное проведение документов помогает следить за движением продуктов и себестоимостью блюд.
                                </li>
                                <li>Сэкономим тебе 10–12 часов в неделю.</li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorFuchsia)}>
                                от 120 Р
                            </span>
                        ),
                    },
                ],
            },
            {
                heading: (
                    <span className={classNames(styles.heading, styles.colorCyan)}>
                        <MegaphoneIcon />
                        Привлечение гостей
                      </span>
                ),
                textarea: (
                    <>
                        <span className={styles.textarea__desktop}>
                          Продумывание программ лояльности — это творческая и важная работа для привлечения гостей. <br/> Общайся с посетителями, а мы займемся технической настройкой акций в CRM-системе Quick Resto.
                        </span>
                        <span className={styles.textarea__tablet}>
                          Продумывание программ лояльности — это творческая и важная работа для
                          привлечения гостей.
                          <br />
                          Общайся с посетителями, а мы займемся технической настройкой акций в
                          CRM-системе Quick&nbsp;Resto.
                        </span>
                        <span className={styles.textarea__mobile}>
                          Продумывание программ лояльности — это творческая и важная работа для
                          привлечения гостей. Общайся с посетителями, а мы займемся технической
                          настройкой акций
                          <br />
                          в CRM-системе Quick&nbsp;Resto.
                        </span>
                    </>
                ),
                table: [
                    {
                        id: 0,
                        col1: 'Услуги',
                        col2: 'Описание',
                        col3: 'Стоимость',
                    },
                    {
                        id: 1,
                        col1: <span className={styles.col1}>Добавим акции и скидки</span>,
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorCyan)}>
                                <li>
                                    Настроим акции, которые хочешь запустить, и мы сделаем так, чтобы они корректно отображалась на терминале. Кассирам не придётся думать, как применить скидку, — всё наглядно.
                                </li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorCyan)}>
                              от 40 Р
                            </span>
                        ),
                    },
                    {
                        id: 2,
                        col1: (
                            <span className={styles.col1}>Внесём гостей в CRM и&nbsp;разделим их по группам </span>
                        ),
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorCyan)}>
                                <li>Добавим гостей в базу.</li>
                                <li>
                                    Научим настраивать разный уровень привилегий, чтобы возвращать и радовать постоянных гостей.
                                </li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorCyan)}>
                                от 120 Р
                            </span>
                        ),
                    },
                    {
                        id: 3,
                        col1: <span className={styles.col1}>Обучим модулю CRM</span>,
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorCyan)}>
                                <li>Расскажем о принципах и способах создания бонусной системы.</li>
                                <li>
                                    Научим создавать акции, комбо, специальные предложения и промокоды с подходящими примерами для твоего типа заведения.
                                </li>
                                <li>
                                    Покажем, как работает настройка начисления бонусов и оповещения гостей. Полную программу курса уточняй у менеджера
                                </li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorCyan)}>
                                от 200 Р
                            </span>
                        ),
                    },
                ],
            },
            {
                heading: (
                    <span className={classNames(styles.heading, styles.colorPurple)}>
                        <BonnetIcon />
                        Обучение
                      </span>
                ),
                textarea: (
                    <>
                        <span className={styles.textarea__desktop}>
                          Если хочешь действовать самостоятельно, мы обучим тебя работе в
                          системе или проверим настройки
                          <br />
                          уже действующего облака. Узнаешь, как использовать автоматизацию,
                          чтобы получать больше выгоды.
                        </span>
                        <span className={styles.textarea__tablet}>
                          Если хочешь действовать самостоятельно, мы обучим тебя работе в
                          системе или проверим настройки
                          <br />
                          уже действующего облака. Узнаешь, как использовать автоматизацию,
                          чтобы получать больше выгоды.
                        </span>
                                        <span className={styles.textarea__mobile}>
                          Если хочешь действовать самостоятельно, мы обучим тебя работе в
                          системе или проверим настройки уже действующего облака. Узнаешь, как
                          использовать автоматизацию, чтобы получать больше выгоды.
                        </span>
                    </>
                ),
                table: [
                    {
                        id: 0,
                        col1: 'Услуги',
                        col2: 'Описание',
                        col3: 'Стоимость',
                    },
                    {
                        id: 1,
                        rowStyles: styles.rowStyles,
                        col1: <span className={styles.col1}>Аудит работы в бэк-офисе</span>,
                        col2: (
                            <>
                                <span
                                    className={classNames(styles.col2__heading, styles.colorPurple)}
                                >
                                  Углубленный аудит
                                    {' '}
                                    <span>от 400 Р</span>
                                </span>
                                <ul className={classNames(styles.col2__ul, styles.colorPurple)}>
                                    <li>
                                        Поправим слабые места и сделаем так, чтобы система полноценно помогала в бизнесе.
                                    </li>
                                    <li>
                                        Сможешь уменьшить затраты в заведении и найдёшь способы заработать больше денег с помощью маркетинговых акций и инструментов аналитики.
                                    </li>
                                </ul>
                            </>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorPurple)}>
                            от 400 Р
                          </span>
                        ),
                    },
                    {
                        id: 2,
                        rowStyles: styles.rowStyles,
                        col1: <span className={styles.col1} />,
                        col2: (
                            <>
                                <span
                                    className={classNames(styles.col2__heading, styles.colorPurple)}
                                >
                                  Аудит инвентаризации
                                    {' '}
                                    <span>от 100 Р</span>
                                </span>
                                <ul className={classNames(styles.col2__ul, styles.colorPurple)}>
                                    <li>
                                        Проверим расхождения в остатках на складе и в системе учёта, выявим причины.
                                    </li>
                                    <li>
                                        Проверим действия персонала, отвечающего за кухню и склад
                                    </li>
                                </ul>
                            </>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorPurple)}>
                            от 250 Р
                          </span>
                        ),
                    },
                    {
                        id: 3,
                        col1: <span className={styles.col1}>Курс «Quick Resto за 4 дня»</span>,
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorPurple)}>
                                <li>
                                    Освоишь: CRM-систему и IT-решения для привлечения гостей, инструменты аналитики и контроля, учёт продуктов и другое.
                                </li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorPurple)}>
                                от 650 Р
                            </span>
                        ),
                    },
                ],
            },
        ]
    }

    if (locale === 'kz-KZ') {
        return [
            {
                heading: (
                    <span className={classNames(styles.heading, styles.colorBluePrimary)}>
                        <DiscountTimeIcon />
                        Сатуды бастау
                    </span>
                ),
                textarea: (
                    <>
                        <span className={styles.textarea__desktop}>
                          Мекемені іске қосу — бұл көп жұмыс. Бізге жүйені теңшеуді тапсырыңыз, <br />
                            жөндеу, персоналды және жектізушілерді іздеу үшін уақытты босатыңыз.
                        </span>
                        <span className={styles.textarea__tablet}>
                          Мекемені іске қосу — бұл көп жұмыс. Бізге жүйені теңшеуді тапсырыңыз, <br />
                            жөндеу, персоналды және жектізушілерді іздеу үшін уақытты босатыңыз.
                        </span>
                        <span className={styles.textarea__mobile}>
                          Мекемені іске қосу — бұл көп жұмыс. Бізге жүйені теңшеуді тапсырыңыз,
                            жөндеу, персоналды және жектізушілерді іздеу үшін уақытты босатыңыз.
                        </span>
                    </>
                ),
                table: [
                    {
                        id: 0,
                        col1: 'Қызметтер',
                        col2: 'Сипаттамасы',
                        col3: 'Құны',
                    },
                    {
                        id: 1,
                        col1: (
                            <span className={styles.col1}>
                                Онлайн-кассаны
                                <br />
                                есепке қоямыз.
                            </span>
                        ),
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorBluePrimary)}>
                                <li>WebKassa-да тіркейміз</li>
                                <li>
                                    ФДО-на қосамыз
                                </li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorBluePrimary)}>
                               9 000 ₸ бастап
                            </span>
                        ),
                    },
                    {
                        id: 2,
                        col1: (
                            <span className={styles.col1}>
                                Жабдықты қосамыз және теңшейміз
                            </span>
                        ),
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorBluePrimary)}>
                                <li>Онлай-кассаны, принтерлерді, ақша жәшігін орнатамыз және теңшейміз</li>
                                <li>Роутерді теңшейміз</li>
                                <li>
                                    Бүкіл жабдық жиынтығын дайындаймыз, ал біздің дүкенде жабдықты сатып алған кезде тегін жеткізуді жасаймыз.
                                </li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorBluePrimary)}>
                                24 000 ₸ бастап
                            </span>
                        ),
                    },
                    {
                        id: 3,
                        col1: (
                            <span className={styles.col1}>
                                Номенклатураны
                                <br />
                                толтырамыз
                            </span>
                        ),
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorBluePrimary)}>
                                <li>Тағамдарыңызды және сусындарыңызды техкарталармен бірге қосамыз.</li>
                                <li>Дәл есепке алу үшін қоймада өнімдердің дұрыс сипаттамасын теңшейміз.</li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorBluePrimary)}>
                                9 000 ₸ бастап
                            </span>
                        ),
                    },
                    {
                        id: 4,
                        col1: <span className={styles.col1}>Тағамдық құндылықты қосамыз</span>,
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorBluePrimary)}>
                                <li>
                                    Олардың орташа көрсеткіштері бойынша ингредиенттер үшін тағамдық құндылықты жазамыз,
                                    тағамдардың тағамдық құндылығы автоматты түрде есептеледі.
                                </li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorBluePrimary)}>
                                9 000 ₸ бастап
                            </span>
                        ),
                    },
                ],
            },
            {
                heading: (
                    <span className={classNames(styles.heading, styles.colorFuchsia)}>
                        <SellingPlanIcon />
                        Өсу нүктелерін іздеу
                    </span>
                ),
                textarea: (
                    <>
                        <span className={styles.textarea__desktop}>
                         Жаңа идеялар мен өсу нүктелерін іздеу үшін ресурстарды сақтау маңызды<br/>
                        болатын кезеңде біз аналитика үшін автоматты есептілікті теңшейміз және бірқатар басқа міндеттерді жеңілдетеміз.
                        </span>
                        <span className={styles.textarea__tablet}>
                          Жаңа идеялар мен өсу нүктелерін іздеу үшін ресурстарды сақтау маңызды<br/>
                        болатын кезеңде біз аналитика үшін автоматты есептілікті теңшейміз және бірқатар басқа міндеттерді жеңілдетеміз.
                        </span>
                        <span className={styles.textarea__mobile}>
                          Жаңа идеялар мен өсу нүктелерін іздеу үшін ресурстарды сақтау маңызды
                        болатын кезеңде біз аналитика үшін автоматты есептілікті теңшейміз және бірқатар басқа міндеттерді жеңілдетеміз.
                        </span>
                    </>
                ),
                table: [
                    {
                        id: 0,
                        col1: 'Қызметтер',
                        col2: 'Сипаттамасы',
                        col3: 'Құны',
                    },
                    {
                        id: 1,
                        col1: (
                            <span className={styles.col1}>
                                Аналитика үшін виджеттерді теңшейміз
                            </span>
                        ),
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorFuchsia)}>
                                <li>
                                    Қажетті сату көрсеткіштерін бейнеленеміз: түсім, тағамдар, әр қызметкер, мекемелер
                                    бойынша. Қандай арналарды тарту керектігін көресіз.
                                </li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorFuchsia)}>
                                3000 ₸ бастап
                            </span>
                        ),
                    },
                    {
                        id: 2,
                        col1: (
                            <span className={styles.col1}>
                                Тағамдарды терминалда маржиналдығы бойынша орналастырамыз
                            </span>
                        ),
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorFuchsia)}>
                                <li>
                                    Тағамдарды талдаймыз және терминалда бірінші позицияларда маржиналдығы көбірек болатын
                                    тағамдар тұратындай етіп орналастырамыз. Кассирлер оларды бірінші кезекте ұсына алады
                                    және орташа чекті ұлғайта алады.

                                </li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorFuchsia)}>
                                18 000 ₸ бастап
                            </span>
                        ),
                    },
                    {
                        id: 3,
                        col1: (
                            <span className={styles.col1}>
                                Апта сайын кіріс<br />жүкқұжаттарын енгіземіз
                            </span>
                        ),
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorFuchsia)}>
                                <li>
                                    Құжаттарды уақытылы жүргізу өнімдердің қозғалысын және тағамдардың өзіндік құнын
                                    қадағалауға қалай көмектесетінін көрсетеміз.
                                </li>
                                <li>Сізге аптасына 10-12 сағатты үнемдейміз.</li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorFuchsia)}>
                               18 000 ₸ бастап
                            </span>
                        ),
                    },
                ],
            },
            {
                heading: (
                    <span className={classNames(styles.heading, styles.colorCyan)}>
                        <MegaphoneIcon />
                        Қонақтарды тарту
                    </span>
                ),
                textarea: (
                    <>
                        <span className={styles.textarea__desktop}>
                          Адалдық бағдарламаларын ойластыру - бұл қонақтарды тарту үшін шығармашылық және маңызды жұмыс.<br/>
                            Келушілермен қарым-қатынас жасаңыз, ал біз Quick Resto CRM-жүйесінде акцияларды техникалық теңшеумен айналысамыз.
                        </span>
                        <span className={styles.textarea__tablet}>
                         Адалдық бағдарламаларын ойластыру - бұл қонақтарды тарту үшін шығармашылық және маңызды жұмыс.<br/>
                            Келушілермен қарым-қатынас жасаңыз, ал біз Quick Resto CRM-жүйесінде акцияларды техникалық теңшеумен айналысамыз.
                        </span>
                        <span className={styles.textarea__mobile}>
                          Адалдық бағдарламаларын ойластыру - бұл қонақтарды тарту үшін шығармашылық және маңызды жұмыс.<br/>
                            Келушілермен қарым-қатынас жасаңыз, ал біз Quick Resto CRM-жүйесінде акцияларды техникалық теңшеумен айналысамыз.
                        </span>
                    </>
                ),
                table: [
                    {
                        id: 0,
                        col1: 'Қызметтер',
                        col2: 'Сипаттамасы',
                        col3: 'Құны',
                    },
                    {
                        id: 1,
                        col1: <span className={styles.col1}>Акциялар мен жеңілдіктерді қосамыз</span>,
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorCyan)}>
                                <li>
                                    Іске қосқыңыз келетін акцияларды теңшейміз, және олар терминалда дұрыс көрсетілетіндей
                                    жасаймыз. Кассирлерге жеңілдікті қалай қолдануға болатынын ойлаудың керегі жоқ, - бәрі көрнекі түрде.
                                </li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorCyan)}>
                                6 000 ₸ бастап
                            </span>
                        ),
                    },
                    {
                        id: 2,
                        col1: (
                            <span className={styles.col1}>
                                Қонақтарды CRM-ге енгіземіз
                                <br />
                                және оларды топтарға бөлеміз  
                            </span>
                        ),
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorCyan)}>
                                <li>Қонақтарды қорға қосамыз.</li>
                                <li>
                                    Тұрақты қонақтарды қайтару және қуанту үшін артықшылықтардың әр түрлі деңгейін теңшеуге үйретеміз.
                                </li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorCyan)}>
                                17 000 ₸ бастап
                            </span>
                        ),
                    },
                    {
                        id: 3,
                        col1: <span className={styles.col1}>Обучим модулю CRM</span>,
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorCyan)}>
                                <li>Бонустық жүйені құру қағидаттары мен тәсілдері туралы айтып береміз.</li>
                                <li>
                                    Сіздің мекеме түріңіз үшін қолайлы мысалдары бар акцияларды, комболарды, арнайы ұсыныстарды және промокодтарды жасауға үйретеміз.

                                </li>
                                <li>
                                    Бонустарды есептеуді және қонақтарды хабардар етуді теңшеу қалай жұмыс істейтінін көрсетеміз. Курстың толық бағдарламасын менеджерден нақтылаңыз
                                </li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorCyan)}>
                                28 000 ₸ бастап
                            </span>
                        ),
                    },
                ],
            },
            {
                heading: (
                    <span className={classNames(styles.heading, styles.colorPurple)}>
                        <BonnetIcon />
                        Оқыту
                    </span>
                ),
                textarea: (
                    <>
                        <span className={styles.textarea__desktop}>
                          Егер өздігінен әрекет еткіңіз келсе, біз сізді жүйеде жұмыс істеуге үйретеміз немесе қазірдің өзінде<br />
                            істеп тұрған бұлттың теңшелімдерін тексереміз. Көбірек пайда алу үшін автоматтандыруды қалай пайдаланатынын білесіз.
                        </span>
                        <span className={styles.textarea__tablet}>
                          Егер өздігінен әрекет еткіңіз келсе, біз сізді жүйеде жұмыс істеуге үйретеміз немесе қазірдің өзінде<br />
                            істеп тұрған бұлттың теңшелімдерін тексереміз. Көбірек пайда алу үшін автоматтандыруды қалай пайдаланатынын білесіз.
                        </span>
                        <span className={styles.textarea__mobile}>
                          Егер өздігінен әрекет еткіңіз келсе, біз сізді жүйеде жұмыс істеуге үйретеміз немесе қазірдің өзінде
                            істеп тұрған бұлттың теңшелімдерін тексереміз. Көбірек пайда алу үшін автоматтандыруды қалай пайдаланатынын білесіз.
                        </span>
                    </>
                ),
                table: [
                    {
                        id: 0,
                        col1: 'Қызметтер',
                        col2: 'Сипаттамасы',
                        col3: 'Құны',
                    },
                    {
                        id: 1,
                        rowStyles: styles.rowStyles,
                        col1: <span className={styles.col1}>Бэк-офисте жұмыстың аудиті</span>,
                        col2: (
                            <>
                                <span
                                    className={classNames(styles.col2__heading, styles.colorPurple)}
                                >
                                  Тереңдетілген аудит
                                    {' '}
                                    <span>17 000 ₸ бастап</span>
                                </span>
                                <ul className={classNames(styles.col2__ul, styles.colorPurple)}>
                                    <li>
                                        Осал жерлерін түзетеміз және жүйе бизнесте толыққанды көмектесетіндей жасаймыз.
                                    </li>
                                    <li>
                                        Мекемеде шығындарды азайта аласыз және маркетинг акциялары мен аналитика құралдары  көмегімен көбірек ақша табу тәсілдерін табасыз.
                                    </li>
                                </ul>
                            </>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorPurple)}>
                               17 000 ₸ бастап
                            </span>
                        ),
                    },
                    {
                        id: 2,
                        rowStyles: styles.rowStyles,
                        col1: <span className={styles.col1} /> ,
                        col2: (
                            <>
                                <span
                                    className={classNames(styles.col2__heading, styles.colorPurple)}
                                >
                                  Түгендеу аудиті
                                    {' '}
                                    <span>30 000 ₸ бастап</span>
                                </span>
                                <ul className={classNames(styles.col2__ul, styles.colorPurple)}>
                                    <li>
                                        Қоймадағы қалдықтардағы және есепке алу жүйесіндегі алшақтықтарды тексереміз, себептерді анықтаймыз.
                                    </li>
                                    <li>Асүй мен қоймаға жауап беретін персоналдың іс-әрекеттерін тексереміз</li>
                                </ul>
                            </>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorPurple)}>
                                30 000 ₸ бастап
                            </span>
                        ),
                    },
                    {
                        id: 4,
                        col1: <span className={styles.col1}>«Quick Resto 4 күнде» курсы</span>,
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorPurple)}>
                                <li>
                                    Қонақтарды тарту үшін CRM-жүйені және ІТ-шешімдерді, аналитика және бақылау құралдарын, өнімдерді есепке алуды және т.б. игересіз.
                                </li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorPurple)}>
                                79 000 ₸ бастап
                            </span>
                        ),
                    },
                ],
            },
        ]
    }

    if (locale === 'ru-KZ') {
        return [
            {
                heading: (
                    <span className={classNames(styles.heading, styles.colorBluePrimary)}>
                        <DiscountTimeIcon />
                        Старт продаж
                    </span>
                ),
                textarea: (
                    <>
                        <span className={styles.textarea__desktop}>
                          Запуск заведения — это море работы. Делегируй нам настройку системы,
                          <br />
                          освободи время для ремонта, поиска персонала и поставщиков.
                        </span>
                                        <span className={styles.textarea__tablet}>
                          Запуск заведения — это море работы. Делегируй нам настройку системы,
                          <br />
                          освободи время для ремонта, поиска персонала и поставщиков.
                        </span>
                                        <span className={styles.textarea__mobile}>
                          Запуск заведения — это море работы. Делегируй нам настройку системы,
                          освободи время для ремонта, поиска персонала и поставщиков.
                        </span>
                    </>
                ),
                table: [
                    {
                        id: 0,
                        col1: 'Услуги',
                        col2: 'Описание',
                        col3: 'Стоимость',
                    },
                    {
                        id: 1,
                        col1: (
                            <span className={styles.col1}>
                                Поставим на учёт
                                <br />
                                онлайн-кассу
                            </span>
                        ),
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorBluePrimary)}>
                                <li>Зарегистрируем в WebKassa</li>
                                <li>
                                    Подключим к ОФД
                                </li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorBluePrimary)}>
                               от 9 000 ₸
                            </span>
                        ),
                    },
                    {
                        id: 2,
                        col1: (
                            <span className={styles.col1}>
                                Подключим и настроим
                                <br />
                                оборудование
                            </span>
                        ),
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorBluePrimary)}>
                                <li>Установим и настроим онлайн-кассу, принтеры, денежный ящик</li>
                                <li>Настроим роутер</li>
                                <li>
                                    Подготовим весь комплект оборудования, а при покупке оборудования
                                    в нашем магазине сделаем бесплатную доставку
                                </li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorBluePrimary)}>
                                от 24 000 ₸
                            </span>
                        ),
                    },
                    {
                        id: 3,
                        col1: (
                            <span className={styles.col1}>
                                Заполним
                                <br />
                                номенклатуру
                            </span>
                        ),
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorBluePrimary)}>
                                <li>Добавим в меню ваши блюда и напитки с техкартами.</li>
                                <li>Настроим корректное списание продуктов на складе для точного учёта.</li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorBluePrimary)}>
                                от 9 000 ₸
                            </span>
                        ),
                    },
                    {
                        id: 4,
                        col1: <span className={styles.col1}>Добавим пищевую ценность</span>,
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorBluePrimary)}>
                                <li>
                                    Пропишем пищевую ценность для ингредиентов по их средним
                                    показателям, пищевая ценность блюд посчитается автоматически.
                                </li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorBluePrimary)}>
                                от 9 000 ₸
                            </span>
                        ),
                    },
                ],
            },
            {
                heading: (
                    <span className={classNames(styles.heading, styles.colorFuchsia)}>
                        <SellingPlanIcon />
                        Поиск точек роста
                    </span>
                ),
                textarea: (
                    <>
                        <span className={styles.textarea__desktop}>
                         На этапе, когда важно сохранять ресурсы для поиска новых идей и точек роста, <br/>
                        мы настроим автоматическую отчётность для аналитики и упростим ряд других задач.
                        </span>
                        <span className={styles.textarea__tablet}>
                          На этапе, когда важно сохранять ресурсы для поиска новых идей и точек
                          роста,
                          <br />
                          мы настроим автоматическую отчётность для аналитики и упростим ряд
                          других задач.
                        </span>
                        <span className={styles.textarea__mobile}>
                          На этапе, когда важно сохранять ресурсы для поиска новых идей и точек
                          роста, мы настроим автоматическую отчётность для аналитики и упростим
                          ряд других задач.
                        </span>
                    </>
                ),
                table: [
                    {
                        id: 0,
                        col1: 'Услуги',
                        col2: 'Описание',
                        col3: 'Стоимость',
                    },
                    {
                        id: 1,
                        col1: (
                            <span className={styles.col1}>
                                Настроим виджеты
                                <br />
                                для аналитики
                            </span>
                        ),
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorFuchsia)}>
                                <li>
                                    Сделаем отображение нужных показателей продаж: по выручке, блюдам,
                                    каждому сотруднику, заведениям. Увидишь, какие каналы нужно
                                    подтянуть.
                                </li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorFuchsia)}>
                                от 3000 ₸
                            </span>
                        ),
                    },
                    {
                        id: 2,
                        col1: (
                            <span className={styles.col1}>
                                Расположим блюда
                                <br />
                                на терминале
                                <br />
                                по маржинальности
                            </span>
                        ),
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorFuchsia)}>
                                <li>
                                    Проанализируем и расположим блюда на терминале так, чтобы на
                                    первых позициях стояли более маржинальные. Кассиры смогут
                                    предлагать их в первую очередь и увеличивать средний чек.
                                </li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorFuchsia)}>
                                от 18 000 ₸
                            </span>
                        ),
                    },
                    {
                        id: 3,
                        col1: (
                            <span className={styles.col1}>
                                Еженедельно будем вносить
                                <br />
                                приходные накладные
                            </span>
                        ),
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorFuchsia)}>
                                <li>
                                    Покажем, как своевременное проведение документов помогает следить
                                    за движением продуктов и себестоимостью блюд.
                                </li>
                                <li>Сэкономим тебе 10–12 часов в неделю.</li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorFuchsia)}>
                               от 18 000 ₸
                            </span>
                        ),
                    },
                ],
            },
            {
                heading: (
                    <span className={classNames(styles.heading, styles.colorCyan)}>
                        <MegaphoneIcon />
                        Привлечение гостей
                    </span>
                ),
                textarea: (
                    <>
                        <span className={styles.textarea__desktop}>
                          Продумывание программ лояльности — это творческая и важная работа для привлечения гостей. <br/> Общайся с посетителями, а мы займемся технической настройкой акций в CRM-системе Quick Resto.
                        </span>
                                        <span className={styles.textarea__tablet}>
                          Продумывание программ лояльности — это творческая и важная работа для
                          привлечения гостей.
                          <br />
                          Общайся с посетителями, а мы займемся технической настройкой акций в
                          CRM-системе Quick&nbsp;Resto.
                        </span>
                                        <span className={styles.textarea__mobile}>
                          Продумывание программ лояльности — это творческая и важная работа для
                          привлечения гостей. Общайся с посетителями, а мы займемся технической
                          настройкой акций
                          <br />
                          в CRM-системе Quick&nbsp;Resto.
                        </span>
                    </>
                ),
                table: [
                    {
                        id: 0,
                        col1: 'Услуги',
                        col2: 'Описание',
                        col3: 'Стоимость',
                    },
                    {
                        id: 1,
                        col1: <span className={styles.col1}>Добавим акции и скидки</span>,
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorCyan)}>
                                <li>
                                    Настроим акции, которые хочешь запустить, и мы сделаем так, чтобы
                                    они корректно отображалась на терминале. Кассирам не придётся
                                    думать, как применить скидку, — всё наглядно.
                                </li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorCyan)}>
                                от 6 000 ₸
                            </span>
                        ),
                    },
                    {
                        id: 2,
                        col1: (
                            <span className={styles.col1}>
                                Внесём гостей в CRM
                                <br />
                                и разделим их по группам
                            </span>
                        ),
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorCyan)}>
                                <li>Добавим гостей в базу.</li>
                                <li>
                                    Научим настраивать разный уровень привилегий, чтобы возвращать и
                                    радовать постоянных гостей.
                                </li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorCyan)}>
                                от 17 000 ₸
                            </span>
                        ),
                    },
                    {
                        id: 3,
                        col1: <span className={styles.col1}>Обучим модулю CRM</span>,
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorCyan)}>
                                <li>Расскажем о принципах и способах создания бонусной системы.</li>
                                <li>
                                    Научим создавать акции, комбо, специальные предложения и промокоды
                                    с подходящими примерами для твоего типа заведения.
                                </li>
                                <li>
                                    Покажем, как работает настройка начисления бонусов и оповещения
                                    гостей. Полную программу курса уточняй у менеджера
                                </li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorCyan)}>
                                от 28 000 ₸
                            </span>
                        ),
                    },
                ],
            },
            {
                heading: (
                    <span className={classNames(styles.heading, styles.colorPurple)}>
                        <BonnetIcon />
                        Обучение
                    </span>
                ),
                textarea: (
                    <>
                        <span className={styles.textarea__desktop}>
                          Если хочешь действовать самостоятельно, мы обучим тебя работе в системе или проверим настройки уже действующего облака. Узнаешь, как использовать автоматизацию, чтобы получать больше выгоды.
                        </span>
                                        <span className={styles.textarea__tablet}>
                          Если хочешь действовать самостоятельно, мы обучим тебя работе в
                          системе или проверим настройки
                          <br />
                          уже действующего облака. Узнаешь, как использовать автоматизацию,
                          чтобы получать больше выгоды.
                        </span>
                                        <span className={styles.textarea__mobile}>
                          Если хочешь действовать самостоятельно, мы обучим тебя работе в
                          системе или проверим настройки уже действующего облака. Узнаешь, как
                          использовать автоматизацию, чтобы получать больше выгоды.
                        </span>
                    </>
                ),
                table: [
                    {
                        id: 0,
                        col1: 'Услуги',
                        col2: 'Описание',
                        col3: 'Стоимость',
                    },
                    {
                        id: 1,
                        rowStyles: styles.rowStyles,
                        col1: <span className={styles.col1}>Аудит работы в бэк-офисе</span>,
                        col2: (
                            <>
                                <span
                                    className={classNames(styles.col2__heading, styles.colorPurple)}
                                >
                                  Углубленный аудит
                                    {' '}
                                    <span>от 17 000 ₸</span>
                                </span>
                                <ul className={classNames(styles.col2__ul, styles.colorPurple)}>
                                    <li>
                                        Поправим слабые места и сделаем так, чтобы система полноценно помогала в бизнесе.
                                    </li>
                                    <li>
                                        Сможешь уменьшить затраты в заведении и найдёшь способы заработать больше денег с помощью маркетинговых акций и инструментов аналитики.
                                    </li>
                                </ul>
                            </>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorPurple)}>
                               от 17 000 ₸
                            </span>
                        ),
                    },
                    {
                        id: 2,
                        rowStyles: styles.rowStyles,
                        col1: <span className={styles.col1} />,
                        col2: (
                            <>
                                <span
                                    className={classNames(styles.col2__heading, styles.colorPurple)}
                                >
                                  Аудит инвентаризации
                                    {' '}
                                    <span>от 30 000 ₸</span>
                                </span>
                                <ul className={classNames(styles.col2__ul, styles.colorPurple)}>
                                    <li>
                                        Проверим расхождения в остатках на складе и в системе учёта,
                                        выявим причины.
                                    </li>
                                    <li>Проверим действия персонала, отвечающего за кухню и склад</li>
                                </ul>
                            </>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorPurple)}>
                                от 30 000 ₸
                            </span>
                        ),
                    },
                    {
                        id: 4,
                        col1: <span className={styles.col1}>Курс «Quick Resto за 4 дня»</span>,
                        col2: (
                            <ul className={classNames(styles.col2__ul, styles.colorPurple)}>
                                <li>
                                    Освоишь: CRM-систему и IT-решения для привлечения гостей, инструменты аналитики и контроля, учёт продуктов и другое.
                                </li>
                            </ul>
                        ),
                        col3: (
                            <span className={classNames(styles.col3, styles.colorPurple)}>
                                от 79 000 ₸
                            </span>
                        ),
                    },
                ],
            },
        ]
    }

    return [
        {
            heading: (
                <span className={classNames(styles.heading, styles.colorBluePrimary)}>
        <DiscountTimeIcon />
        Старт продаж
      </span>
            ),
            textarea: (
                <>
        <span className={styles.textarea__desktop}>
          Запуск заведения — это море работы. Делегируй нам настройку системы,
          <br />
          освободи время для ремонта, поиска персонала и поставщиков.
        </span>
                    <span className={styles.textarea__tablet}>
          Запуск заведения — это море работы. Делегируй нам настройку системы,
          <br />
          освободи время для ремонта, поиска персонала и поставщиков.
        </span>
                    <span className={styles.textarea__mobile}>
          Запуск заведения — это море работы. Делегируй нам настройку системы,
          освободи время для ремонта, поиска персонала и поставщиков.
        </span>
                </>
            ),
            table: [
                {
                    id: 0,
                    col1: 'Услуги',
                    col2: 'Описание',
                    col3: 'Стоимость',
                },
                {
                    id: 1,
                    col1: (
                        <span className={styles.col1}>
            Поставим на учёт
            <br />
            онлайн-кассу
          </span>
                    ),
                    col2: (
                        <ul className={classNames(styles.col2__ul, styles.colorBluePrimary)}>
                            <li>Зарегистрируем онлайн-кассу в ФНС</li>
                            <li>
                                Настроим личный кабинет ОФД, активируем или продлим его
                                обслуживание
                            </li>

                        </ul>
                    ),
                    col3: (
                        <span className={classNames(styles.col3, styles.colorBluePrimary)}>
            от 3 000 ₽
          </span>
                    ),
                },
                {
                    id: 2,
                    col1: (
                        <span className={styles.col1}>
                            Привезём и настроим
                            <br />
                            оборудование
                          </span>
                    ),
                    col2: (
                        <ul className={classNames(styles.col2__ul, styles.colorBluePrimary)}>
                            <li>Установим и настроим онлайн-кассу, принтеры, денежный ящик</li>
                            <li>Настроим роутер</li>
                            <li>
                                При покупке оборудования в нашем магазине, сделаем бесплатную доставку
                                (по Москве в пределах МКАД)
                            </li>
                        </ul>
                    ),
                    col3: (
                        <span className={classNames(styles.col3, styles.colorBluePrimary)}>
            от 5 000 ₽
          </span>
                    ),
                },
                {
                    id: 3,
                    col1: (
                        <span className={styles.col1}>
            Заполним
            <br />
            номенклатуру
          </span>
                    ),
                    col2: (
                        <ul className={classNames(styles.col2__ul, styles.colorBluePrimary)}>
                            <li>Добавим в меню ваши блюда и напитки с техкартами.</li>
                            <li>Настроим корректное списание продуктов на складе для точного учёта.</li>
                        </ul>
                    ),
                    col3: (
                        <span className={classNames(styles.col3, styles.colorBluePrimary)}>
            от 3 000 ₽
          </span>
                    ),
                },
                {
                    id: 4,
                    col1: <span className={styles.col1}>Добавим пищевую ценность</span>,
                    col2: (
                        <ul className={classNames(styles.col2__ul, styles.colorBluePrimary)}>
                            <li>
                                Пропишем пищевую ценность для ингредиентов по их средним
                                показателям, пищевая ценность блюд посчитается автоматически.
                            </li>
                        </ul>
                    ),
                    col3: (
                        <span className={classNames(styles.col3, styles.colorBluePrimary)}>
            от 3 000 ₽
          </span>
                    ),
                },
            ],
        },
        {
            heading: (
                <span className={classNames(styles.heading, styles.colorFuchsia)}>
        <SellingPlanIcon />
        Поиск точек роста
      </span>
            ),
            textarea: (
                <>
        <span className={styles.textarea__desktop}>
          На этапе, когда важно сохранять ресурсы для поиска новых идей и точек
          роста,
          <br />
          мы настроим автоматическую отчётность для аналитики и упростим ряд
          других задач.
        </span>
                    <span className={styles.textarea__tablet}>
          На этапе, когда важно сохранять ресурсы для поиска новых идей и точек
          роста,
          <br />
          мы настроим автоматическую отчётность для аналитики и упростим ряд
          других задач.
        </span>
                    <span className={styles.textarea__mobile}>
          На этапе, когда важно сохранять ресурсы для поиска новых идей и точек
          роста, мы настроим автоматическую отчётность для аналитики и упростим
          ряд других задач.
        </span>
                </>
            ),
            table: [
                {
                    id: 0,
                    col1: 'Услуги',
                    col2: 'Описание',
                    col3: 'Стоимость',
                },
                {
                    id: 1,
                    col1: (
                        <span className={styles.col1}>
            Настроим виджеты
            <br />
            для аналитики
          </span>
                    ),
                    col2: (
                        <ul className={classNames(styles.col2__ul, styles.colorFuchsia)}>
                            <li>
                                Сделаем отображение нужных показателей продаж: по выручке, блюдам,
                                каждому сотруднику, заведениям. Увидишь, какие каналы нужно
                                подтянуть.
                            </li>
                        </ul>
                    ),
                    col3: (
                        <span className={classNames(styles.col3, styles.colorFuchsia)}>
            от 500 ₽
          </span>
                    ),
                },
                {
                    id: 2,
                    col1: (
                        <span className={styles.col1}>
            Расположим блюда
            <br />
            на терминале
            <br />
            по маржинальности
          </span>
                    ),
                    col2: (
                        <ul className={classNames(styles.col2__ul, styles.colorFuchsia)}>
                            <li>
                                Проанализируем и расположим блюда на терминале так, чтобы на
                                первых позициях стояли более маржинальные. Кассиры смогут
                                предлагать их в первую очередь и увеличивать средний чек.
                            </li>
                        </ul>
                    ),
                    col3: (
                        <span className={classNames(styles.col3, styles.colorFuchsia)}>
            от 3 000 ₽
          </span>
                    ),
                },
                {
                    id: 3,
                    col1: (
                        <span className={styles.col1}>
            Еженедельно будем вносить
            <br />
            приходные накладные
          </span>
                    ),
                    col2: (
                        <ul className={classNames(styles.col2__ul, styles.colorFuchsia)}>
                            <li>
                                Покажем, как своевременное проведение документов помогает следить
                                за движением продуктов и себестоимостью блюд.
                            </li>
                            <li>Сэкономим тебе 10–12 часов в неделю.</li>
                        </ul>
                    ),
                    col3: (
                        <span className={classNames(styles.col3, styles.colorFuchsia)}>
            от 3 000 ₽
          </span>
                    ),
                },
                {
                    id: 4,
                    col1: (
                        <span className={styles.col1}>
            Настроим связь с «ЕГАИС»
            <br />
            под ключ
          </span>
                    ),
                    col2: (
                        <ul className={classNames(styles.col2__ul, styles.colorFuchsia)}>
                            <li>Изготовим токен с ключом ЕГАИС.</li>
                            <li>
                                Зарегистрируем личный кабинет в ЕГАИС, получим ключ RSA, установим
                                УТМ-модуль на ПК, настроим систему ЕГАИСИК и свяжем её с Quick
                                Resto.
                            </li>
                        </ul>
                    ),
                    col3: (
                        <span className={classNames(styles.col3, styles.colorFuchsia)}>
            от 9 000 ₽
          </span>
                    ),
                },
            ],
        },
        {
            heading: (
                <span className={classNames(styles.heading, styles.colorCyan)}>
        <MegaphoneIcon />
        Привлечение гостей
      </span>
            ),
            textarea: (
                <>
        <span className={styles.textarea__desktop}>
          Продумывание программ лояльности — это творческая и важная работа для
          привлечения гостей.
          <br />
          Общайся с посетителями, а мы займемся технической настройкой акций в
          CRM-системе Quick&nbsp;Resto.
        </span>
                    <span className={styles.textarea__tablet}>
          Продумывание программ лояльности — это творческая и важная работа для
          привлечения гостей.
          <br />
          Общайся с посетителями, а мы займемся технической настройкой акций в
          CRM-системе Quick&nbsp;Resto.
        </span>
                    <span className={styles.textarea__mobile}>
          Продумывание программ лояльности — это творческая и важная работа для
          привлечения гостей. Общайся с посетителями, а мы займемся технической
          настройкой акций
          <br />
          в CRM-системе Quick&nbsp;Resto.
        </span>
                </>
            ),
            table: [
                {
                    id: 0,
                    col1: 'Услуги',
                    col2: 'Описание',
                    col3: 'Стоимость',
                },
                {
                    id: 1,
                    col1: <span className={styles.col1}>Добавим акции и скидки</span>,
                    col2: (
                        <ul className={classNames(styles.col2__ul, styles.colorCyan)}>
                            <li>
                                Настроим акции, которые хочешь запустить, и мы сделаем так, чтобы
                                они корректно отображалась на терминале. Кассирам не придётся
                                думать, как применить скидку, — всё наглядно.
                            </li>
                        </ul>
                    ),
                    col3: (
                        <span className={classNames(styles.col3, styles.colorCyan)}>
            от 1 000 ₽
          </span>
                    ),
                },
                {
                    id: 2,
                    col1: (
                        <span className={styles.col1}>
            Внесём гостей в CRM
            <br />
            и разделим их по группам
          </span>
                    ),
                    col2: (
                        <ul className={classNames(styles.col2__ul, styles.colorCyan)}>
                            <li>Добавим гостей в базу.</li>
                            <li>
                                Научим настраивать разный уровень привилегий, чтобы возвращать и
                                радовать постоянных гостей.
                            </li>
                        </ul>
                    ),
                    col3: (
                        <span className={classNames(styles.col3, styles.colorCyan)}>
            от 2 500 ₽
          </span>
                    ),
                },
                {
                    id: 3,
                    col1: <span className={styles.col1}>Обучим модулю CRM</span>,
                    col2: (
                        <ul className={classNames(styles.col2__ul, styles.colorCyan)}>
                            <li>Расскажем о принципах и способах создания бонусной системы.</li>
                            <li>
                                Научим создавать акции, комбо, специальные предложения и промокоды
                                с подходящими примерами для твоего типа заведения.
                            </li>
                            <li>
                                Покажем, как работает настройка начисления бонусов и оповещения
                                гостей. Полную программу курса уточняй у менеджера
                            </li>
                        </ul>
                    ),
                    col3: (
                        <span className={classNames(styles.col3, styles.colorCyan)}>
            от 4 990 ₽
          </span>
                    ),
                },
            ],
        },
        {
            heading: (
                <span className={classNames(styles.heading, styles.colorPurple)}>
        <BonnetIcon />
        Обучение
      </span>
            ),
            textarea: (
                <>
        <span className={styles.textarea__desktop}>
          Если хочешь действовать самостоятельно, мы обучим тебя работе в
          системе или проверим настройки
          <br />
          уже действующего облака. Узнаешь, как использовать автоматизацию,
          чтобы получать больше выгоды.
        </span>
                    <span className={styles.textarea__tablet}>
          Если хочешь действовать самостоятельно, мы обучим тебя работе в
          системе или проверим настройки
          <br />
          уже действующего облака. Узнаешь, как использовать автоматизацию,
          чтобы получать больше выгоды.
        </span>
                    <span className={styles.textarea__mobile}>
          Если хочешь действовать самостоятельно, мы обучим тебя работе в
          системе или проверим настройки уже действующего облака. Узнаешь, как
          использовать автоматизацию, чтобы получать больше выгоды.
        </span>
                </>
            ),
            table: [
                {
                    id: 0,
                    col1: 'Услуги',
                    col2: 'Описание',
                    col3: 'Стоимость',
                },
                {
                    id: 1,
                    rowStyles: styles.rowStyles,
                    col1: <span className={styles.col1}>Аудит работы в бэк-офисе</span>,
                    col2: (
                        <>
            <span
                className={classNames(styles.col2__heading, styles.colorPurple)}
            >
              Базовый аудит
                {' '}
                <span>от 2 500 ₽</span>
            </span>
                            <ul className={classNames(styles.col2__ul, styles.colorPurple)}>
                                <li>
                                    Проверим, правильно ли настроены методы списания, права доступа,
                                    складская документация и другое.
                                </li>
                                <li>
                                    Выясним, откуда берутся недостачи на складе, найдем, где можно
                                    сэкономить с пользой для бизнеса.
                                </li>
                            </ul>
                        </>
                    ),
                    col3: (
                        <span className={classNames(styles.col3, styles.colorPurple)}>
            от 2 500 ₽
          </span>
                    ),
                },
                {
                    id: 2,
                    rowStyles: styles.rowStyles,
                    col1: <span className={styles.col1} />,
                    col2: (
                        <>
            <span
                className={classNames(styles.col2__heading, styles.colorPurple)}
            >
              Углубленный аудит
                {' '}
                <span>от 10 000 ₽</span>
            </span>
                            <ul className={classNames(styles.col2__ul, styles.colorPurple)}>
                                <li>
                                    Поправим слабые места и сделаем так, чтобы система полноценно
                                    помогала в бизнесе.
                                </li>
                                <li>
                                    Сможешь уменьшить затраты в заведении и найдёшь способы
                                    заработать больше денег с помощью маркетинговых акций и
                                    инструментов аналитики.
                                </li>
                            </ul>
                        </>
                    ),
                    col3: (
                        <span className={classNames(styles.col3, styles.colorPurple)}>
            от 10 000 ₽
          </span>
                    ),
                },
                {
                    id: 3,
                    rowStyles: styles.rowStyles,
                    col1: <span className={styles.col1} />,
                    col2: (
                        <>
            <span
                className={classNames(styles.col2__heading, styles.colorPurple)}
            >
              Аудит инвентаризации
                {' '}
                <span>от 5 500 ₽</span>
            </span>
                            <ul className={classNames(styles.col2__ul, styles.colorPurple)}>
                                <li>
                                    Проверим расхождения в остатках на складе и в системе учёта,
                                    выявим причины.
                                </li>
                                <li>Проверим действия персонала, отвечающего за кухню и склад</li>
                            </ul>
                        </>
                    ),
                    col3: (
                        <span className={classNames(styles.col3, styles.colorPurple)}>
            от 5 500 ₽
          </span>
                    ),
                },
                {
                    id: 4,
                    col1: <span className={styles.col1}>Курс «Quick Resto за 4 дня»</span>,
                    col2: (
                        <ul className={classNames(styles.col2__ul, styles.colorPurple)}>
                            <li>
                                Освоишь: CRM-систему и IT-решения для привлечения гостей,
                                инструменты аналитики и контроля, учёт продуктов и другое.
                            </li>
                        </ul>
                    ),
                    col3: (
                        <span className={classNames(styles.col3, styles.colorPurple)}>
            от 14 990 ₽
          </span>
                    ),
                },
            ],
        },
    ]
}
