import React from "react"
import classNames from "classnames"

import AlarmIcon from "../../../../assets/_V2/svg/AlarmIcon"

import Card1 from "./assets/Card1"
import Card2 from "./assets/Card2"

import styles from "./styles.module.scss"
import {Locales} from "../../../../localization/types";

export const getHeadingTextareaAndCardListHalfWith = (locale :Locales) => {
	if (locale === 'kz-KZ') {
		return {
			heading: (
				<span className={classNames(styles.heading, styles.colorGreen)}>
			<AlarmIcon />
			Уақытты басқару
		</span>
			),
			textarea: (
				<>
			<span className={styles.textarea__desktop}>
				Біз кертартпа жұмыспен айналасып, автоматтандыруды теңшеп жатқан кезде, сіз бизнеске пайда әкелетін нәрсемен айналаса аласыз.
			</span>
					<span className={styles.textarea__tablet}>
				Біз кертартпа жұмыспен айналасып, автоматтандыруды теңшеп жатқан кезде, сіз бизнеске пайда әкелетін нәрсемен айналаса аласыз.
			</span>
					<span className={styles.textarea__mobile}>
				Біз кертартпа жұмыспен айналасып, автоматтандыруды теңшеп жатқан кезде, сіз бизнеске пайда әкелетін нәрсемен айналаса аласыз.
			</span>
				</>
			),
			cardList: [
				{
					id: 0,
					heading: "Жалғыздан",
					textarea:
						"Әрқашан ұзақ болады. Бүгін уақытты неге жұмсайтынын таңдауға тура келеді\n",
					content: (
						<ul className={classNames(styles.ul, styles.colorGreen)}>
							<li>ЕГАИС теңшелімдерін түсінуге немесе зал үшін жиһазды іздеуге</li>
							<li>Техкарталарды толтыруға немесе әлеуметтік желілер үшін посттар жазуға</li>
							<li>Салық қызметіне баруға немесе жеткізушіні таңдауға</li>
						</ul>
					),
					mediaContent: <Card1 />,
				},
				{
					id: 1,
					heading: "Quick Resto бірге",
					textarea: "Үздік сервисті жасауға және өсу нүктелерін табуға уақыт бар\n",
					content: (
						<ul className={classNames(styles.ul, styles.colorGreen)}>
							<li>
								Бизнесті оңтайландыруға және мекемеде 24/7 болудың керегі жоқ жағдай жасауға
							</li>
							<li>Сатып алуға шығыстарды қысқартуға және маржиналдықты арттыруға</li>
							<li>Қонақтарға ұнайтын атмосфера құруға</li>
						</ul>
					),
					mediaContent: <Card2 />,
				},
			],
		}
	}
	return {
		heading: (
			<span className={classNames(styles.heading, styles.colorGreen)}>
			<AlarmIcon />
			Управление временем
		</span>
		),
		textarea: (
			<>
			<span className={styles.textarea__desktop}>
				Пока мы будем заниматься рутиной и настраивать автоматизацию,
				<br />
				ты сможешь заняться тем, что приносит прибыль бизнесу.
			</span>
				<span className={styles.textarea__tablet}>
				Пока мы будем заниматься рутиной и настраивать автоматизацию,
				<br />
				ты сможешь заняться тем, что приносит прибыль бизнесу.
			</span>
				<span className={styles.textarea__mobile}>
				Пока мы будем заниматься рутиной и настраивать автоматизацию, ты сможешь
				заняться тем, что приносит прибыль бизнесу.
			</span>
			</>
		),
		cardList: [
			{
				id: 0,
				heading: "В одиночку",
				textarea:
					"Всегда дольше. Приходится выбирать, на что сегодня потратить время",
				content: (
					<ul className={classNames(styles.ul, styles.colorGreen)}>
						<li>Разбираться в настройках ЕГАИС или искать мебель для зала</li>
						<li>Заполнять техкарты или писать посты для соцсетей</li>
						<li>Идти в налоговую или выбирать поставщика</li>
					</ul>
				),
				mediaContent: <Card1 />,
			},
			{
				id: 1,
				heading: "С Quick Resto",
				textarea: "Есть время, чтобы делать лучший сервис и находить точки роста",
				content: (
					<ul className={classNames(styles.ul, styles.colorGreen)}>
						<li>
							Оптимизировать бизнес и создавать условия, при которых не надо быть
							в заведении 24/7
						</li>
						<li>Сократить расходы на закупки и повысить маржинальность</li>
						<li>Создать атмосферу, которую полюбят гости</li>
					</ul>
				),
				mediaContent: <Card2 />,
			},
		],
	}
}

export default {
	heading: (
		<span className={classNames(styles.heading, styles.colorGreen)}>
			<AlarmIcon />
			Управление временем
		</span>
	),
	textarea: (
		<>
			<span className={styles.textarea__desktop}>
				Пока мы будем заниматься рутиной и настраивать автоматизацию,
				<br />
				ты сможешь заняться тем, что приносит прибыль бизнесу.
			</span>
			<span className={styles.textarea__tablet}>
				Пока мы будем заниматься рутиной и настраивать автоматизацию,
				<br />
				ты сможешь заняться тем, что приносит прибыль бизнесу.
			</span>
			<span className={styles.textarea__mobile}>
				Пока мы будем заниматься рутиной и настраивать автоматизацию, ты сможешь
				заняться тем, что приносит прибыль бизнесу.
			</span>
		</>
	),
	cardList: [
		{
			id: 0,
			heading: "В одиночку",
			textarea:
				"Всегда дольше. Приходится выбирать, на что сегодня потратить время",
			content: (
				<ul className={classNames(styles.ul, styles.colorGreen)}>
					<li>Разбираться в настройках ЕГАИС или искать мебель для зала</li>
					<li>Заполнять техкарты или писать посты для соцсетей</li>
					<li>Идти в налоговую или выбирать поставщика</li>
				</ul>
			),
			mediaContent: <Card1 />,
		},
		{
			id: 1,
			heading: "С Quick Resto",
			textarea: "Есть время, чтобы делать лучший сервис и находить точки роста",
			content: (
				<ul className={classNames(styles.ul, styles.colorGreen)}>
					<li>
						Оптимизировать бизнес и создавать условия, при которых не надо быть
						в заведении 24/7
					</li>
					<li>Сократить расходы на закупки и повысить маржинальность</li>
					<li>Создать атмосферу, которую полюбят гости</li>
				</ul>
			),
			mediaContent: <Card2 />,
		},
	],
}
