import React from 'react'
import classNames from 'classnames'

import SuitcaseIcon from '../../../../assets/_V2/svg/SuitcaseIcon'

import CardIconOne from './assets/CardIconOne'
import CardIconTwo from './assets/CardIconTwo'
import CardIconThree from './assets/CardIconThree'
import CardIconFour from './assets/CardIconFour'

import styles from './styles.module.scss'
import {Locales} from "../../../../localization/types";

export const getHeadingTextareaAndCardList = (locale :Locales) => {
    if (locale === "kz-KZ"){
        return {
            heading: (
                <span className={classNames(styles.heading, styles.colorBluePrimary)}>
      <SuitcaseIcon />
      Процесс
    </span>
            ),
            textarea: (
                <>
      <span className={styles.contentDesktop}>
        Шынайы мекемелер тәжірибесінде жұмыс істейміз, бизнесті іске қосуға және дамытуға көмектесеміз.<br /> Жоспар бойынша әрекет етеміз, мақсатқа нақты қозғаламыз.
      </span>
                    <span className={styles.contentTablet}>
        Шынайы мекемелер тәжірибесінде жұмыс істейміз, бизнесті іске қосуға және дамытуға көмектесеміз. Жоспар бойынша әрекет етеміз, мақсатқа нақты қозғаламыз.
      </span>
                    <span className={styles.contentMobile}>
        Шынайы мекемелер тәжірибесінде жұмыс істейміз, бизнесті іске қосуға және дамытуға көмектесеміз. Жоспар бойынша әрекет етеміз, мақсатқа нақты қозғаламыз.
      </span>
                </>
            ),
            cardList: [
                {
                    theme: styles.white,
                    id: 0,
                    icon: <CardIconOne />,
                    heading: 'Брифинг',
                    content: (
                        <ul className={styles.ul}>
                            <li>Оқыту мақсатын бизнес міндеттерімен келістіреміз</li>
                            <li>Мекеме бойынша кейс құрамыз</li>
                            <li>Оқыту қатысушылары, компаниядағы рөлдері </li>
                        </ul>
                    ),
                },
                {
                    theme: styles.white,
                    id: 1,
                    icon: <CardIconTwo />,
                    heading: 'Бағдарлама',
                    content: (
                        <ul className={styles.ul}>
                            <li>Кейс негізінде оқу жоспарын әзірлейміз</li>
                            <li>Оқу үшін техникалық брифті жүргіземіз</li>
                            <li>Бірінші сабақтың күнін тағайындаймыз</li>
                        </ul>
                    ),
                },
                {
                    theme: styles.white,
                    id: 2,
                    icon: <CardIconThree />,
                    heading: 'Оқыту',
                    content: (
                        <ul className={styles.ul}>
                            <li>Үй тапсырмасы бар онлайн формат</li>
                            <li>Сабақта келесі сабақтың күнін тағайындаймыз</li>
                            <li>Сабақты ҮТ орындалғаннан кейін ғана өткіземіз</li>
                        </ul>
                    ),
                },
                {
                    theme: styles.white,
                    id: 3,
                    icon: <CardIconFour />,
                    heading: 'Бағалау',
                    content: (
                        <ul className={styles.ul}>
                            <li>Оқу нәтижелері бойынша кері байланысты жинаймыз</li>
                            <li>
                                Қызметкерлер мен басшы үшін әдістемелік ұсынымдарды жасаймыз
                            </li>
                        </ul>
                    ),
                },
            ],
        }
    }

    return {
        heading: (
            <span className={classNames(styles.heading, styles.colorBluePrimary)}>
      <SuitcaseIcon />
      Процесс
    </span>
        ),
        textarea: (
            <>
      <span className={styles.contentDesktop}>
        Работаем на опыте реальных заведений, помогаем запускать и развивать
        бизнес.
        <br />
        Действуем по плану, двигаемся чётко к цели.
      </span>
                <span className={styles.contentTablet}>
        Работаем на опыте реальных заведений, помогаем запускать и развивать
        бизнес.
        <br />
        Действуем по плану, двигаемся чётко к цели.
      </span>
                <span className={styles.contentMobile}>
        Работаем на опыте реальных заведений, помогаем запускать и развивать бизнес. Действуем по плану, двигаемся чётко к цели.
      </span>
            </>
        ),
        cardList: [
            {
                theme: styles.white,
                id: 0,
                icon: <CardIconOne />,
                heading: 'Брифинг',
                content: (
                    <ul className={styles.ul}>
                        <li>Согласовываем цель обучения с задачи бизнеса</li>
                        <li>Составляем кейс по заведению</li>
                        <li>Участники обучения, их роли в компании</li>
                    </ul>
                ),
            },
            {
                theme: styles.white,
                id: 1,
                icon: <CardIconTwo />,
                heading: 'Программа',
                content: (
                    <ul className={styles.ul}>
                        <li>Разрабатываем план обучения на основе кейса</li>
                        <li>Проводим технический бриф для обучения</li>
                        <li>Назначаем дату первого занятия</li>
                    </ul>
                ),
            },
            {
                theme: styles.white,
                id: 2,
                icon: <CardIconThree />,
                heading: 'Обучение',
                content: (
                    <ul className={styles.ul}>
                        <li>Онлайн формат с домашним заданием</li>
                        <li>На занятии назначаем дату следующего</li>
                        <li>Занятие проводим только после выполнения ДЗ</li>
                    </ul>
                ),
            },
            {
                theme: styles.white,
                id: 3,
                icon: <CardIconFour />,
                heading: 'Оценка',
                content: (
                    <ul className={styles.ul}>
                        <li>Собираем обратную связь по результатам обучения</li>
                        <li>
                            Составляем методические рекомендации для сорудников и руководителя
                        </li>
                    </ul>
                ),
            },
        ],
    }
}
export default {
  heading: (
    <span className={classNames(styles.heading, styles.colorBluePrimary)}>
      <SuitcaseIcon />
      Процесс
    </span>
  ),
  textarea: (
    <>
      <span className={styles.contentDesktop}>
        Работаем на опыте реальных заведений, помогаем запускать и развивать
        бизнес.
        <br />
        Действуем по плану, двигаемся чётко к цели.
      </span>
      <span className={styles.contentTablet}>
        Работаем на опыте реальных заведений, помогаем запускать и развивать
        бизнес.
        <br />
        Действуем по плану, двигаемся чётко к цели.
      </span>
      <span className={styles.contentMobile}>
        Работаем на опыте реальных заведений, помогаем запускать и развивать бизнес. Действуем по плану, двигаемся чётко к цели.
      </span>
    </>
  ),
  cardList: [
    {
      theme: styles.white,
      id: 0,
      icon: <CardIconOne />,
      heading: 'Брифинг',
      content: (
        <ul className={styles.ul}>
          <li>Согласовываем цель обучения с задачи бизнеса</li>
          <li>Составляем кейс по заведению</li>
          <li>Участники обучения, их роли в компании</li>
        </ul>
      ),
    },
    {
      theme: styles.white,
      id: 1,
      icon: <CardIconTwo />,
      heading: 'Программа',
      content: (
        <ul className={styles.ul}>
          <li>Разрабатываем план обучения на основе кейса</li>
          <li>Проводим технический бриф для обучения</li>
          <li>Назначаем дату первого занятия</li>
        </ul>
      ),
    },
    {
      theme: styles.white,
      id: 2,
      icon: <CardIconThree />,
      heading: 'Обучение',
      content: (
        <ul className={styles.ul}>
          <li>Онлайн формат с домашним заданием</li>
          <li>На занятии назначаем дату следующего</li>
          <li>Занятие проводим только после выполнения ДЗ</li>
        </ul>
      ),
    },
    {
      theme: styles.white,
      id: 3,
      icon: <CardIconFour />,
      heading: 'Оценка',
      content: (
        <ul className={styles.ul}>
          <li>Собираем обратную связь по результатам обучения</li>
          <li>
            Составляем методические рекомендации для сорудников и руководителя
          </li>
        </ul>
      ),
    },
  ],
}
