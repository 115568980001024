import React from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"

type CardListType = {
	id: number // id таблицы
	heading: string | JSX.Element // Заголовок
	textarea: string | JSX.Element // Описание
	content: string | JSX.Element // Контент
	mediaContent: JSX.Element // Картинка
}

interface Props {
	className?: string // Класс
	anchor?: string // Якорь
	data: {
		heading: string | JSX.Element // Заголовок
		textarea: string | JSX.Element // Описание
		cardList: [CardListType] // Список карточек
	}
}

// Компонент с заголовком, описание и карточками с половиной ширины
// Пример: /service/
export default function HeadingTextareaAndCardListHalfWith(props: Props) {
	const data = props.data || {}

	const renderCardList = (): JSX.Element => (
		<div className={classNames(styles.cardList, styles.tableDesktop)}>
			{data.cardList.map((card) => (
				<div
					key={card.id}
					className={styles.card}
				>
					<div className={styles.card__row}>
						<div className={styles.card__col}>
							{card.mediaContent}
						</div>
						<div className={styles.card__col}>
							<h3 className={styles.card__heading}>{card.heading}</h3>
							<p className={styles.card__textarea}>{card.textarea}</p>
							<div className={styles.card__content}>
								{card.content}
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	)

	return (
		<section id={props.anchor} className={classNames(styles.section, props.className)}>
			<h2 className={styles.heading}>{data.heading}</h2>
			<p className={styles.textarea}>{data.textarea}</p>

			{data?.cardList?.length > 0 && renderCardList()}
		</section>
	)
}
