import * as React from "react"
import { SVGProps } from "react"

const CardIconOne = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="151"
		height="150"
		viewBox="0 0 151 150"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M24.3921 132H72.3921H120.392L72.3921 52L24.3921 132Z"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinejoin="round"
		/>
		<path
			d="M110.911 116H129.992L101.192 68L97.592 74L95.792 77"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M33.873 116H14.7922L43.5922 68L47.1922 74L48.9922 77"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M53.1831 84L65.1893 103.997L72.3921 92L79.5758 103.997L91.5906 84"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M91.6011 84L79.5948 103.997L72.3921 92L65.2084 103.997L53.1936 84"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M72.3921 52V20H100.392L92.3921 28L100.392 37H80.3921"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default CardIconOne
