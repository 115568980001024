import * as React from "react"
import { SVGProps } from "react"

const BonnetIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="25"
		height="24"
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_11756_269061)">
			<path
				d="M6.8828 11.1923V17.0371C6.8828 18.5888 9.49007 19.8467 12.892 19.8467C16.2938 19.8467 18.9186 18.5888 18.9186 17.0371L18.9185 17.0221V11.2613M2.42969 9.02919L12.892 4.15332L23.3542 9.02919L12.892 13.9051L2.42969 9.02919Z"
				stroke="#7D828C"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_11756_269061">
				<rect
					width="24"
					height="24"
					fill="white"
					transform="translate(0.89209)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default BonnetIcon
