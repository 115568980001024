import * as React from "react"
import { SVGProps } from "react"

const CardIconFour = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="151"
		height="150"
		viewBox="0 0 151 150"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M75.7776 44.0311V26.4128L86.8253 26.4128C91.6904 26.4128 95.6344 30.3568 95.6344 35.222C95.6344 40.0871 91.6904 44.0311 86.8253 44.0311H75.7776ZM75.7776 44.0311V52.8437M75.7776 44.0311H69.1639M75.7776 52.8437V61.7243M75.7776 52.8437H69.1639M75.7776 52.8437H91.2009"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<circle
			cx="82.399"
			cy="44.0684"
			r="33.0684"
			stroke="#3021E5"
			strokeWidth="3"
		/>
		<path
			d="M32.8967 101.933C32.8967 92.6786 40.3987 85.1765 49.6529 85.1765H86.9379C91.565 85.1765 95.316 88.9276 95.316 93.5547C95.316 98.1818 91.565 101.933 86.9379 101.933L65.4013 101.933M58.0396 124.871H74.6966C85.4274 124.871 95.7718 120.866 103.704 113.639L128.803 90.775C132.803 87.1307 132.949 80.8835 129.122 77.057C125.442 73.3765 119.483 73.3498 115.769 76.9973L104.357 88.2067L101.504 91.0091"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M32.6803 102.091L18.8833 114.034L43.8497 139L57.6462 124.885"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default CardIconFour
